<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout align-start>
      <v-flex>
        <div id="contenedor" style="position: absolute; visibility: hidden;"></div>

        <!-- Persona -->
        <v-dialog v-model="verPersona" max-width="700px" persistent>
          <v-card>
            <v-toolbar dark dense class="primary" flat>
              <v-toolbar-title>Nuevo Proveedor</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="guardarPersona" :loading="desabilitarPer" :disabled="desabilitarPer || !isValidPer">
                <v-icon>save</v-icon>
              </v-btn>
              <v-btn icon @click="closePersona">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-form v-model="isValidPer">
                <v-row class="mt-2">
                  <v-col cols="6" sm="3">
                    <v-select dense v-model="idtipo_documento_pers" :items="tipo_documentos_cli" label="Tipo Documento"
                      :rules="[v => !!v || 'Tipo documento requerido']" @change="num_documento = ''" hide-details>
                    </v-select>
                  </v-col>
                  <v-col cols="10" sm="5">
                    <v-text-field dense v-model.trim="num_documento" label="Numero Documento"
                      :rules="[v => !!v || 'Numero documento requerido', v => (idtipo_documento_pers == 1 ? v.length == 8 || 'Numero DNI' : v.length == 11 || 'Numero RUC')]"
                      :maxlength="idtipo_documento_pers == 1 ? 8 : 11"
                      :disabled="idtipo_documento_pers == 1 ? false : idtipo_documento_pers == 6 ? false : true"
                      @keypress="common.isNum($event)" hide-details></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="1">
                    <div class="text-center">
                      <v-btn x-small fab color="primary" class="white--text" :loading="desabilitarBuscar"
                        :disabled="(idtipo_documento_pers == 1 && num_documento.length == 8 ? false : idtipo_documento_pers == 6 && num_documento.length == 11 ? false : true) || desabilitarBuscar"
                        @click="extraerDocumento(num_documento)">
                        <v-icon>search</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense v-model.trim="nombre"
                      :label="idtipo_documento_pers == 1 ? 'Apellidos y Nombres' : 'Nombre'"
                      :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 3 && v.length <= 200) || 'Nombre debe tener entre 3 a 200 caracteres']"
                      maxlength="100" prepend-inner-icon="person" v-uppercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo" v-model="idubigeo"
                      :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                      prepend-inner-icon="pin_drop" clearable hide-details></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense v-model.trim="direccion" label="Direccion"
                      :rules="[v => v.length <= 200 || 'La dirección no debe tener mas de 200 caracteres']"
                      maxlength="200" prepend-inner-icon="place" v-uppercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field dense v-model.trim="email" label="Email"
                      :rules="[v => v == '' ? !v : /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                      maxlength="60" prepend-inner-icon="mail" v-lowercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field dense v-model.trim="telefono" label="Telefono"
                      :rules="[v => v.length <= 30 || 'El Telefono no debe tener mas de 30 caracteres', Rules.unespacio]"
                      maxlength="30" prepend-inner-icon="phone" v-uppercase hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Persona -->

        <ModalProducto :showDialog="dialogProduct" @closeProduct="dialogProduct = false"
          @dataSearch="dataSearchProduct" />

        <!-- Buscar Producto -->
        <v-dialog v-model="verProductos" max-width="1200px">
          <v-card>
            <v-toolbar dark dense class="primary mb-1" flat>
              <v-toolbar-title><v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon small @click="openProduct">
                      <v-icon>playlist_add</v-icon>
                    </v-btn>
                  </template>
                  <span>Nuevo Producto</span>
                </v-tooltip> Productos</v-toolbar-title>
              <v-spacer />
              <v-text-field dense solo-inverted v-model.trim="busqueda_producto" label="Buscar Productos"
                prepend-inner-icon="search" @keyup="listarProducto()" clearable hide-details></v-text-field>
              <v-spacer />
              <v-btn icon @click="ocultarProductos()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <!-- Mostrar imagen de producto -->
              <v-dialog v-model="selectedZoom" max-width="350px">
                <v-avatar tile size="350">
                  <img :src="'data:image/png;base64,' + imagen_zoom" />
                </v-avatar>
              </v-dialog>
              <!-- Fin Mostrar imagen de producto -->
              <v-data-table :headers="cabeceraProductos" :items="productos" :items-per-page="15" item-key="idproducto"
                show-expand class="elevation-3" @dblclick:row="dblClickRepuesto" dense>
                <template v-slot:item.seleccionar="{ item }">
                  <v-icon class="mr-2" @click="agregarDetalle(item)" color="primary">control_point</v-icon>
                </template>
              <template v-slot:item.imagen="{ item }">
                  <template>
                    <v-avatar tile size="30">
                      <img :src="'data:image/png;base64,' + item.imagen" @click="verImagen(item)"/>
                    </v-avatar>
                  </template>
                </template>

                <template v-slot:item.codigos="{ item }">
                  <strong class="primary--text">{{ item.codigo_producto }} </strong> <br /> {{ item.codigo_producto2 }}
                </template>
                <template v-slot:item.marca="{ item }">
                  <strong class="error--text">{{ item.marca }}</strong>
                </template>
                <template v-slot:item.precio_unit_comprad="{ item }">
                  <div class="text-right">
                    <span class="font-weight-bold">{{ item.precio_unit_comprad | formatearNumero }}</span>
                  </div>
                </template>
                <template v-slot:item.precio_unit_compras="{ item }">
                  <div class="text-right">
                    <span class="font-weight-bold">{{ item.precio_unit_compras | formatearNumero }}</span>
                  </div>
                </template>

                <template v-slot:item.stockactual="{ item }">
                  <template v-if="item.stockactual == item.stock_minimo">
                    <v-chip color="warning" small dark>{{ item.stockactual }}</v-chip>
                  </template>
                  <template v-else-if="item.stockactual > item.stock_minimo">
                    <v-chip color="success" small dark>{{ item.stockactual }}</v-chip>
                  </template>
                  <template v-else>
                    <v-chip color="error" small dark>{{ item.stockactual }}</v-chip>
                  </template>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-row class="mt-0 mb-0">
                      <v-col cols="6" md="4" sm="4">
                        <v-select dense v-model="item.idtipo_afectacion" :items="tipo_afectacion" label="Tipo Afectación"
                          hide-details></v-select>
                      </v-col>
                    </v-row>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Buscar Producto -->

        <!-- Compras -->
        <v-data-table :loading="loading" :headers="cabeceraListarCompra" :items="compras" :items-per-page="15"
          item-key="idmovimiento" show-expand class="elevation-3" v-if="verNuevaCompra == 0" dense>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">COMPRAS</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- Busqueda de compra -->
              <v-text-field dense class="mr-1" v-model.trim="buscar" label="Búsqueda" @keyup="listar()" single-line
                hide-details></v-text-field>
              <!-- Busqueda de compra -->
              <v-spacer />
              <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details append-icon="update"
                    @click:append="listar()" class="mr-1" style="max-width: 220px;"></v-text-field>
                </template>
                <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                  @change="listar()" range></v-date-picker>
              </v-menu>
              <v-spacer />
              <v-btn @click="mostrarNuevaCompra" color="primary" class="hidden-sm-and-down" small outlined>Nuevo</v-btn>
              <v-btn @click="mostrarNuevaCompra" color="primary" class="hidden-md-and-up" fab x-small outlined>
                <v-icon>add</v-icon>
              </v-btn>

              <!-- Modal conograma ingreso cuentas x pagar -->
              <v-dialog v-model="verCronograma" max-width="800px" persistent>
                <v-card>
                  <v-toolbar dark dense class="primary" flat>
                    <v-toolbar-title>Crear Cronograma</v-toolbar-title>
                    <v-spacer />
                    <span>Importe: {{ moneda == "S" ? "S/" : "$" }} {{ importe_total | formatearNumero }}</span>
                  </v-toolbar>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-row no-gutters class="mt-4">
                        <v-col cols="12" class="mb-4">
                          <v-chip color="grey lighten-4" class="mr-1" label>
                            <strong>Proveedor: {{ proveedor }}</strong>
                          </v-chip>
                          <v-chip color="grey lighten-4" label>
                            <strong>Fecha: {{ fecha | formatearFecha }}</strong>
                          </v-chip>
                        </v-col>
                        <v-col cols="6" md="1">
                          <v-text-field dense label="Plazo" v-model="plazo" @keyup.up="plazo++" @keyup.down="plazo--"
                            @keypress="common.isNum($event)" maxlength="2" class="mr-1"></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3">
                          <v-text-field dense type="text" label="Importe" v-model="calcularCuota"
                            :prefix="moneda == 'S' ? 'S/' : '$'" disabled></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="8" class="mb-4">
                          <v-card-actions class="py-0 justify-end">
                            <v-btn color="error" @click="ocultarCronograma()" small outlined>
                              <v-icon left>close</v-icon>Cancelar
                            </v-btn>
                            <v-btn color="primary" @click="guardarCronograma()" :loading="desabilitar"
                              :disabled="desabilitar || !isValid" small outlined>
                              <v-icon left>check</v-icon>Guardar
                            </v-btn>
                          </v-card-actions>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-date-picker scrollable v-model="fecha_cronograma" min="2019-01" :events="eventosCxP"
                            :event-color="obtenerEventoColor"
                            @change="fechaSeleccionada(fecha_cronograma), agregarDetalleCronograma()"></v-date-picker>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <!-- Agremamos tabla detalles de cronograma -->
                          <v-data-table :headers="cabeceraCronograma" :items="cronograma" hide-default-footer
                            class="elevation-3" dense>
                            <template v-slot:item.borrar="{ item }">
                              <v-icon small color="error" class="mr-2"
                                @click="eliminarDetalleConograma(cronograma, item)">delete</v-icon>
                            </template>
                            <template v-slot:item.fecha_vencimiento="{ item }">
                              <v-text-field dense type="text" v-model="item.fecha_vencimiento" readonly hide-details>
                              </v-text-field>
                            </template>
                            <template v-slot:item.cuota="{ item }">
                              <v-text-field dense type="text" v-model="item.cuota"
                                :rules="[v => (!!v && v > 0) || 'Requerido', importe_total == calcularCronograma || 'Deben ser iguales']"
                                maxlength="7" @keypress="common.numDec($event, item.cuota, 2)" onClick="this.select()"
                                hide-details></v-text-field>
                            </template>
                          </v-data-table>
                          <!-- Fin tabla detalles de cronograma -->
                          <div class="text-end">
                            <v-chip class="ma-2" color="primary" label>
                              <strong>Total {{ moneda == "S" ? "S/" : "$" }}:{{ calcularCronograma | formatearNumero
                              }}</strong>
                            </v-chip>
                          </div>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!-- Fin Modal conograma ingreso cuentas x pagar -->

              <!-- Modal detalle cuentas por pagar -->
              <v-dialog v-model="verDetalleCuenta" max-width="400px">
                <v-card>
                  <v-toolbar dark dense class="primary" flat>
                    <v-toolbar-title>Cronograma de pagos</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon @click="ocultarDetalleCuenta()">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="mt-3">
                        <!-- Tabla detalles cronograma -->
                        <v-data-table :headers="cabeceraDetalleCuenta" :items="detalleCuentas" hide-default-footer
                          class="elevation-3" dense>
                          <template v-slot:item.fecha_vencimiento="{ item }">
                            <span class="font-weight-bold">{{ item.fecha_vencimiento | formatearFecha }}</span>
                          </template>
                          <template v-slot:item.importe="{ item }">
                            <span class="font-weight-bold">{{ moneda == "S" ? "S/" : "$" }} {{ item.importe |
                              formatearNumero
                            }}</span>
                          </template>
                        </v-data-table>
                        <!-- Fin Tabla detalles cronograma -->
                        <div class="text-end">
                          <v-chip class="mt-2" label>
                            <strong>Total {{ moneda == "S" ? "S/" : "$" }}:{{ totalDetalleCuenta | formatearNumero
                            }}</strong>
                          </v-chip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!-- Fin Modal detalle cuentas por pagar -->

              <!-- Modal anular compra -->
              <v-dialog v-model="adModal" max-width="310">
                <v-card>
                  <v-card-title class="justify-center">¿Anular Compra?</v-card-title>
                  <v-card-text>
                    Estás a punto de
                    <span>Anular</span>
                    la Compra Nº {{ adId }}
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn color="info" @click="anularCerrar" small outlined>Cancelar</v-btn>
                    <v-btn color="error" @click="anular" small outlined>Anular</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal anular compra -->
            </v-toolbar>
          </template>
          <!-- Opciones -->
          <template v-slot:item.numero="{ item }">
            <span>{{ (item.serie_comprobante + "-" + item.num_comprobante.substr(0, 10)) }}</span>
          </template>
          <template v-slot:item.proveedor="{ item }">
            <span>{{ (item.proveedor).substr(0, 40) }}</span>
          </template>
          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>
          <template v-slot:item.moneda="{ item }">
            <div class="text-right">
              <strong>{{ item.moneda == "S" ? "S/" : "$" }}</strong>
            </div>
          </template>
          <template v-slot:item.importe_total="{ item }">
            <div class="text-right">
              <strong>{{ item.importe_total | formatearNumero }}</strong>
            </div>
          </template>
          <template v-slot:item.estado="{ item }">
            <template v-if="item.estado != 'Anulado'">
              <span>{{ item.estado }}</span>
            </template>
            <template v-else>
              <span class="error--text">{{ item.estado }}</span>
            </template>
          </template>
          <template v-slot:item.opciones="{ item }">
            <template v-if="item.idtipo_pago == 4 && item.estado == 'Cronograma'">
              <v-btn small icon>
                <v-icon color="primary" @click="mostrarDetalleCuentas(item)">date_range</v-icon>
              </v-btn>
            </template>
            <template v-if="item.idtipo_pago == 4 && item.estado == 'Creado'">
              <v-btn small icon>
                <v-icon color="orange" @click="crearCronograma(item)">credit_card</v-icon>
              </v-btn>
            </template>
            <v-btn small icon>
              <v-icon color="primary" @click="verDetallesPedidos(item)">visibility</v-icon>
            </v-btn>
            <v-btn small icon>
              <v-icon color="print" @click="mostrarComprobante(item)">print</v-icon>
            </v-btn>
            <template v-if="item.estado != 'Anulado' && item.idusuario == usuario.idusuario">
              <v-btn small icon>
                <v-icon color="error" @click="anularMostrar(item)">delete</v-icon>
              </v-btn>
            </template>
          </template>
          <!-- Fin Opciones -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Registrado por:</strong>
              {{ item.usuario }}
            </td>
          </template>
        </v-data-table>
        <!-- Fin Compras -->

        <!-- Nueva compra -->
        <v-card v-if="verNuevaCompra == 1" class="elevation-3">
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer />
            <span v-if="editedIndex === -1" class="hidden-sm-and-down">Modifica precio de productos: {{ datos.actpprodcompra }}</span>
            <v-spacer />
            <v-btn icon @click="crearBarCodeTot()" v-if="editedIndex === 1">
              <v-icon>qr_code</v-icon>
            </v-btn>
            <v-text-field dense solo-inverted @keyup.enter="buscarCodigoBarra()" v-model="codigo_barra"
              label="Código Barra" maxlength="30" append-icon="qr_code_scanner" v-if="editedIndex === -1"
              style="max-width: 220px;" hide-details>
            </v-text-field>
          </v-toolbar>
          <v-card-text grid-list-sm v-if="verNuevaCompra">
            <v-form v-model="isValid">
              <v-row>
                <v-col cols="6" md="2" sm="3">
                  <v-select dense v-model="idtipo_servicio" :items="tipo_servicios" label="Moneda"
                    :rules="[v => !!v || 'Servicio requerido']" @change="detalles = []"
                    :disabled="editedIndex === -1 ? false : true" hide-details></v-select>
                </v-col>
                <v-col cols="6" md="2" sm="2">
                  <v-menu v-model="menuFecha" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field dense v-model="fecha_comprobante_formateada" label="Fecha Comprobante"
                        :rules="[v => !!v || 'Fecha Requerido']" readonly v-on="on"
                        :disabled="editedIndex === -1 ? false : true" hide-details></v-text-field>
                    </template>
                    <v-date-picker v-model="fecha_comprobante" @input="menuFecha = false"
                      @change="fechaSelectComprob(fecha_comprobante)"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="2" sm="2" v-if="editedIndex === -1">
                  <v-select dense v-model="comprobantes" :items="tipo_comprobantes" label="Tipo Comprobante"
                    :rules="[v => !!v || 'Comprobante requerido']" @change="comprobanteSeleccionado(comprobantes)"
                    hide-details></v-select>
                </v-col>
                <v-col cols="3" md="1" sm="1">
                  <v-text-field dense v-model="serie_comprobante" label="Serie"
                    :rules="[v => v.length == 4 || 'Requerido', Rules.sinespacio]" :disabled="disabled" maxlength="4"
                    v-uppercase hide-details></v-text-field>
                </v-col>
                <v-col cols="9" md="5" sm="4">
                  <v-text-field dense v-model.trim="num_comprobante" label="Núm. Comprobante"
                    :rules="[v => !!v || 'Requerido', Rules.unespacio]"
                    @blur.prevent="llenarCeroNumComprobante(num_comprobante)" maxlength="35" :disabled="disabled"
                    v-numspace hide-details></v-text-field>
                </v-col>

                <v-col cols="12" md="9" sm="8">
                  <v-autocomplete dense @keyup="selectProveedor()" :search-input.sync="buscar_proveedor"
                    v-model="idpersona" :items="proveedores" label="Proveedor"
                    :rules="[v => !!v || 'Proveeedor requerido']" clearable append-outer-icon="person_add"
                    @click:append-outer="mostrarPersona" :disabled="editedIndex === -1 ? false : true"
                    hide-details></v-autocomplete>
                </v-col>
                <v-col cols="10" md="2" sm="3" v-if="editedIndex === -1">
                  <v-select dense v-model="idtipo_pago" :items="tipo_pagos" label="Tipo Pago"
                    :rules="[v => !!v || 'Pago requerido']" hide-details></v-select>
                </v-col>
                <v-col cols="2" md="1" sm="1" v-if="editedIndex === -1">
                  <div class="text-center">
                    <v-btn @click="mostrarProductos()" x-small fab color="primary" outlined>
                      <v-icon>list</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-data-table :headers="numserie == false ? cabeceraDetallesCompra : cabeceraDetallesCompraSerie"
                    :items="detalles" disable-pagination hide-default-footer dense class="elevation-1">
                    <template v-slot:header.descripcion="{ header }">
                      {{ header.text }}<v-icon class="ml-2" @click="numserie = !numserie">pin</v-icon>
                    </template>
                    <template v-slot:item.num="{ item }">
                      <td>{{ detalles.indexOf(item) + 1 }}</td>
                    </template>
                    <template v-slot:item.borrar="{ item }">
                      <v-icon small color="info" @click="mostrarBarCode(item)" v-if="editedIndex === 1">
                        qr_code</v-icon>
                      <v-icon small color="error" @click="eliminarDetallePedido(detalles, item)"
                        v-if="editedIndex === -1">
                        delete_forever</v-icon>
                    </template>
                    <template v-slot:item.serie="{ item }">
                      <v-text-field dense type="text" v-model.trim="item.serie" maxlength="80"
                        :disabled="editedIndex === -1 ? false : true" hide-details>
                      </v-text-field>
                    </template>
                    <template v-slot:item.stock_ingreso="{ item }">
                      <v-text-field dense type="text" @keyup.up="item.stock_ingreso++" @keyup.down="item.stock_ingreso--"
                        v-model.number="item.stock_ingreso" :rules="[v => (!!v && v > 0) || 'Requerido']" maxlength="4"
                        @keypress="common.isNum($event)" onClick="this.select()" class="center-input"
                        hide-details></v-text-field>
                    </template>
                    <template v-slot:item.valor_unit_compra="{ item }">
                      <v-text-field dense type="text" v-model="item.valor_unit_compra"
                        :rules="[v => !!v || 'Requerido', item.valor_unit_compra > 0 || 'Error']" maxlength="10"
                        @keypress="common.numDec($event, item.valor_unit_compra, 4)" onClick="this.select()"
                        :disabled="editedIndex === -1 ? false : true" hide-details></v-text-field>
                    </template>
                    <template v-slot:item.descto_item="{ item }">
                      <v-text-field dense type="text" v-model="item.descto_item" maxlength="10"
                        @keypress="common.numDec($event, item.descto_item, 4)" onClick="this.select()"
                        :disabled="editedIndex === -1 ? false : true" hide-details></v-text-field>
                    </template>
                    <template v-slot:item.otros_item="{ item }">
                      <v-text-field dense type="text" v-model="item.otros_item" maxlength="10"
                        @keypress="common.numDec($event, item.otros_item, 4)" onClick="this.select()"
                        :disabled="editedIndex === -1 ? false : true" hide-details></v-text-field>
                    </template>

                    <template v-slot:item.subtotal="{ item }">
                      <div class="text-right">
                        <span class="font-weight-bold">{{
                          common.roundOut((common.valInt(item.stock_ingreso) *
                            common.valDec(item.valor_unit_compra) -
                            common.valDec(item.descto_item)), 2)
                          | formatearNumero
                        }}</span>
                      </div>
                    </template>
                  </v-data-table>
                  <v-container>
                    <v-row no-gutters align="end" justify="end">
                      <v-col cols="10" align="end">
                        <strong>Descuento Global (-) {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense v-model="descto_global" maxlength="7"
                          @keypress="common.numDec($event, descto_global, 2)" onClick="this.select()" class="right-input"
                          :disabled="editedIndex === -1 ? false : true" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Descuento por Item (-) {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="descto_item_total = common.roundOut(common.valDec(calcularDescuentoItem), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Seguro {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense v-model="seguro" maxlength="7" @keypress="common.numDec($event, seguro, 2)"
                          onClick="this.select()" class="right-input" :disabled="editedIndex === -1 ? false : true"
                          hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Gravada {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="subtotal_gravado = common.roundOut((common.valDec(calcularTotal) - common.valDec(descto_global) + common.valDec(seguro)), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>IGV {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="igv_gravado = common.roundOut(((common.valDec(calcularTotal) - common.valDec(descto_global) + common.valDec(seguro)) * igv), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Importe Total {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="importe_total = common.roundOut((common.valDec(subtotal_gravado) + common.valDec(igv_gravado)), 2).toFixed(2)"
                          :rules="[v => v > 0 || 'Importe mayor a cero']" class="right-input" hide-details>
                        </v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Gratuita {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="gratuito = common.roundOut(calcularGratuitoTotal, 2).toFixed(2)" class="right-input"
                          hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Otros Item {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="otros_item_total = common.roundOut(common.valDec(calcularOtrosItem), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 px-4 justify-center">
            <v-btn color="error" @click="ocultarNuevaCompra()" small outlined>
              <v-icon left>close</v-icon>Cancelar
            </v-btn>
            <v-btn color="primary" @click="guardarCompra()" :loading="desabilitar" :disabled="desabilitar || !isValid"
              v-if="editedIndex === -1" small outlined>
              <v-icon left>check</v-icon>Crear Compra
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- Fin Nueva compra -->

        <!-- Modal código barra -->
        <v-dialog v-model="adModalBarra" max-width="350" persistent>
          <v-card>
            <v-toolbar dark dense class="info" flat>
              <v-toolbar-title>{{ codigo_producto }} {{ marca }}</v-toolbar-title>
              <v-spacer />
              <v-text-field dense solo-inverted type="text" v-model="cantCodigos" label="Cantidad"
                :rules="[v => (!!v && v > 0) || 'Requerido']" maxlength="4" hide-details class="center-input"
                style="max-width: 60px;" @keypress="common.isNum($event)" onClick="this.select()"></v-text-field>
              <v-spacer />
              <v-btn icon @click="crearBarCode()" :disabled="cantCodigos > 0 ? false : true">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn icon @click="cerrarBarCode">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card>
        </v-dialog>
        <!-- Fin Modal código barra -->

        <!-- Modal impresión -->
        <v-dialog v-model="comprobanteModal" max-width="700px" scrollable>
          <v-card>
            <v-toolbar dark dense class="primary" flat>
              <v-toolbar-title>{{ comprobante }}</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="printExternal()">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn icon @click="savePdf()">
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn icon @click="ocultarComprobante()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text style="height: 400px;" class="px-0">
              <pdf v-for="i in numPages" :key="i" :src="srcPdf" :page="i"></pdf>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal impresión -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import jsPDF from "jspdf";
import Barcode from "jsbarcode";
import pdf from "vue-pdf";
import { mapState, mapActions } from "vuex";
import ModalProducto from "./Modal_Producto.vue";

export default {
  components: {
    pdf,
    ModalProducto
  },
  data() {
    return {
      compras: [],
      eventosCxP: [],
      cabeceraListarCompra: [
        //{ text: "CdMv", value: "idmovimiento" },
        //{ text: "USUARIO", value: "usuario" },
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "NÚMERO", value: "numero", sortable: false },
        { text: "CdPd", value: "idpedido", sortable: false },
        { text: "PROVEEDOR", value: "proveedor", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "M", value: "moneda", sortable: false },
        { text: "IMPORTE", value: "importe_total", sortable: false },
        { text: "PAGO", value: "descrip_pago", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false, width: 120 },
      ],

      cabeceraProductos: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "+", value: "seleccionar", align: "center", sortable: false },
        { text: "IMG", value: "imagen", align: "center", sortable: false },
        { text: "U/M", value: "idunidad_medida", sortable: false },
        { text: "CÓDIGOS", value: "codigos", sortable: false },
        { text: "NOMBRE", value: "descripcion", sortable: false },
        { text: "CATEGORÍA", value: "categoria", sortable: false },
        { text: "MARCA", value: "marca", sortable: false, divider: true },
        { text: "P/U/C $", value: "precio_unit_comprad", sortable: false },
        { text: "P/U/C S/", value: "precio_unit_compras", sortable: false },
        { text: "STOCK", value: "stockactual", sortable: false, align: "center" },
      ],

      cabeceraCronograma: [
        { text: "OPC", value: "borrar", sortable: false },
        { text: "FECHA VCTO", align: "center", value: "fecha_vencimiento", sortable: false },
        { text: "IMPORTE", align: "center", value: "cuota", sortable: false },
      ],

      cabeceraDetalleCuenta: [
        { text: "#", value: "numero", sortable: false },
        { text: "FECHA VCTO", value: "fecha_vencimiento", sortable: false },
        { text: "IMPORTE", align: "center", value: "importe", sortable: false },
        { text: "ESTADO", align: "center", value: "estado", sortable: false },
      ],

      cabeceraDetallesCompra: [
        { text: "", value: "borrar", sortable: false },
        { text: "#", value: "num", align: "center", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "SERIE", value: "serie", sortable: false, align: ' d-none' },
        { text: "CANT.", value: "stock_ingreso", align: "center", sortable: false },
        { text: "P/V/UNIT.", value: "valor_unit_compra", sortable: false },
        { text: "DSCTO", value: "descto_item", sortable: false },
        { text: "OTROS", value: "otros_item", sortable: false },
        { text: "TOTAL", value: "subtotal", sortable: false },
      ],
      cabeceraDetallesCompraSerie: [
        { text: "", value: "borrar", sortable: false },
        { text: "#", value: "num", align: "center", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "SERIE", value: "serie", sortable: false },
        { text: "CANT.", value: "stock_ingreso", align: "center", sortable: false },
        { text: "P/V/UNIT.", value: "valor_unit_compra", sortable: false },
        { text: "DSCTO", value: "descto_item", sortable: false },
        { text: "OTROS", value: "otros_item", sortable: false },
        { text: "TOTAL", value: "subtotal", sortable: false },
      ],
      loading: false,

      numserie: false,
      buscar_proveedor: "",
      buscar_ubigeo: "",
      codigo_barra: "",
      busqueda_producto: "",
      productos: [],

      detalles: [],
      detalleCuentas: [],
      buscar: "",
      idmovimiento: "",

      idpedido: 0,
      idpersona: "",
      proveedores: [],
      idtipo_servicio: 4, // Compra ME
      tipo_servicios: [],

      igv: 0,
      tc: 0,

      importe: 0,
      descto_global: 0,
      descto_item_total: 0,
      otros_item_total: 0,
      seguro: 0,
      subtotal_gravado: 0,
      gratuito: 0,
      igv_gravado: 0,
      importe_total: 0,

      verNuevaCompra: 0,
      verProductos: 0,
      editedIndex: -1, // Titulo compras

      selectedZoom: false,
      imagen_zoom: "",

      fecha_comprobante_formateada: "",
      menuFecha: false,

      sucursal: "",
      proveedor: "",
      fecha: "",
      fecha_comprobante: moment().format("YYYY-MM-DD"),

      moneda: "",

      //idtipo_documento: "",
      comprobanteModal: 0,
      adModal: 0,
      adId: "",
      adFecha: "",

      desabilitar: false, // Guardar
      isValid: true, // validamos
      disabled: true, // Serie y numero comprobante

      idtipo_pago: "",
      tipo_pagos: [],
      idtipo_comprobante: "",
      comprobante: "",
      tipo_comprobantes: [],
      comprobantes: "",
      serie_comprobante: "",
      num_comprobante: "",

      // Persona
      nombre: "",
      idtipo_documento_pers: "",
      tipo_documentos_cli: [],
      num_documento: "",
      idubigeo: "",
      ubigeos: [],
      direccion: "",
      telefono: "",
      email: "",
      verPersona: false,
      desabilitarBuscar: false,
      desabilitarPer: false,
      isValidPer: true,
      // Persona

      tipo_afectacion: [],

      verCronograma: 0,
      verDetalleCuenta: 0,

      cronograma: [],
      detalle_cuentas: [],

      stringPdf: "",
      srcPdf: "",
      blobPdf: "",
      numPages: undefined,

      plazo: "1",
      fecha_cronograma_formateada: "",
      fecha_cronograma: moment().format("YYYY-MM-DD"),
      fecha_actual: moment().format("YYYY-MM-DD"),

      dates: [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ],

      dialogProduct: false,

      codigo_producto: "",
      // barcode
      adModalBarra: 0,
      cantCodigos: 0,
      marca: "",
      // fin barcode

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),

    // Titulo pedidos
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Compra" : "Ver Compra";
    },

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },

    // Calcular La cuota para cronograma de deuda
    calcularCuota: function () {
      var resultado = 0;
      if (this.plazo > 0) {
        resultado = (
          parseFloat(this.importe_total) /
          this.common.valInt(this.plazo)
        ).toFixed(2);
      }
      return resultado;
    },
    // Calcula el total del detalle del cronograma
    calcularCronograma: function () {
      var resultado = 0.0;
      for (var i = 0; i < this.cronograma.length; i++) {
        resultado = (
          parseFloat(resultado) + parseFloat(this.cronograma[i].cuota)
        ).toFixed(2);
      }
      return resultado;
    },
    // Sumar detalle de cuenta
    totalDetalleCuenta: function () {
      var resultado = 0.0;
      for (var i = 0; i < this.detalleCuentas.length; i++) {
        resultado = (
          parseFloat(resultado) + parseFloat(this.detalleCuentas[i].importe)
        ).toFixed(2);
      }
      return resultado;
    },

    calcularTotal: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        if (me.detalles[i].idtipo_afectacion == "10") {
          resultado = resultado + me.common.roundOut(me.common.valInt(me.detalles[i].stock_ingreso) * me.common.valDec(me.detalles[i].valor_unit_compra) - me.common.valDec(me.detalles[i].descto_item), 4);
        }
      }
      return resultado;
    },

    calcularGratuitoTotal: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        if (me.detalles[i].idtipo_afectacion == "31") {
          resultado = resultado + me.common.roundOut(me.common.valInt(me.detalles[i].stock_ingreso) * me.common.valDec(me.detalles[i].valor_unit_compra) - me.common.valDec(me.detalles[i].descto_item), 4);
        }
      }
      return resultado;
    },

    calcularDescuentoItem: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado = resultado + me.common.roundOut(me.common.valDec(me.detalles[i].descto_item), 4);
      }
      return resultado;
    },

    calcularOtrosItem: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado = resultado + me.common.roundOut(me.common.valInt(me.detalles[i].stock_ingreso) * me.common.valDec(me.detalles[i].otros_item), 4);
      }
      return resultado;
    },
  },

  watch: {
    verCronograma(val) {
      val || this.ocultarCronograma();
    },
    verDetalleCuenta(val) {
      val || this.ocultarDetalleCuenta();
    },
    comprobanteModal(val) {
      val || this.ocultarComprobante();
    },
    busqueda_producto(newVal) {
      if (newVal === null) {
        this.busqueda_producto = "";
      }
    },
    verProductos(val) {
      val || this.ocultarProductos();
    },
  },

  created() {
    this.listar();
    this.select();
    this.fechaSelectComprob(this.fecha_comprobante);
    this.fechaSeleccionada(this.fecha_cronograma);
    this.cargarEventoCuentaxPagar();
    this.igv = parseFloat(this.datos.igv);
    this.tc = parseFloat(this.datos.tc);
  },

  methods: {
    ...mapActions(["snackBar"]),

    openProduct() {
      this.dialogProduct = true
    },

    dataSearchProduct(data) {
      this.busqueda_producto = data.description;
      this.listarProducto();
    },
    // Convertimos DD/MM/AAAA a AAAA-MM-DD
    fechaConvertida(dia) {
      let arrayFecha = dia.split(["/"]);
      let yymmdd = arrayFecha[2] + "-" + arrayFecha[1] + "-" + arrayFecha[0];
      return yymmdd;
    },

    // Convertimos fecha AAAA-MM-DD a DD/MM/AAAA
    fechaSeleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_cronograma_formateada = ddmmyy;
    },

    fechaSelectComprob(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_comprobante_formateada = ddmmyy;
    },

    //#region SELECT
    selectProveedor(id) {
      let me = this;

      var proveedoresArray = [];

      if (id) {
        axios.get("api/Personas/SelectPersonas/" + id)
          .then(function (response) {
            proveedoresArray = response.data;
            proveedoresArray.map(function (x) {
              me.proveedores.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios.get("api/Personas/SelectPersonasFiltro", {
          params: {
            buscar:
              me.buscar_proveedor == ""
                ? "-"
                : me.buscar_proveedor == null
                  ? "-"
                  : me.buscar_proveedor,
            tipo_doc: "-",
          },
        })
          .then(function (response) {
            proveedoresArray = response.data;
            proveedoresArray.map(function (x) {
              me.proveedores.push({
                text: x.num_documento + " - " + x.nombre,
                value: x.idpersona,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios.get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios.get("api/Ubigeos/SelectUbigeosFiltro", {
          params: {
            buscar:
              me.buscar_ubigeo == ""
                ? "-"
                : me.buscar_ubigeo == null
                  ? "-"
                  : me.buscar_ubigeo,
          },
        })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    select() {
      let me = this;
      var tipo_serviciosArray = [];
      axios.get("api/Tipo_Servicios/SelectMonCompra")
        .then(function (response) {
          tipo_serviciosArray = response.data;
          tipo_serviciosArray.map(function (x) {
            me.tipo_servicios.push({
              text: x.nombre,
              value: x.idtipo_servicio,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_comprobantesArray = [];
      axios.get("api/Tipo_Comprobantes/SelectCompra")
        .then(function (response) {
          tipo_comprobantesArray = response.data;
          tipo_comprobantesArray.map(function (x) {
            me.tipo_comprobantes.push({
              text: x.descripcion,
              value: x.idtipo_comprobante + "-" + x.descripcion,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_documentosArray = [];
      axios.get("api/Tipo_Documentos/Select")
        .then(function (response) {
          tipo_documentosArray = response.data;
          tipo_documentosArray.map(function (x) {
            me.tipo_documentos_cli.push({
              text: x.descripcion,
              value: x.idtipo_documento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_afectacionArray = [];
      axios.get("api/Tipo_Afectacion/Select")
        .then(function (response) {
          tipo_afectacionArray = response.data;
          tipo_afectacionArray.map(function (x) {
            me.tipo_afectacion.push({
              text: x.descripcion,
              value: x.idtipo_afectacion,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_pagosArray = [];
      axios.get("api/Tipo_Pagos/SelectCompra")
        .then(function (response) {
          tipo_pagosArray = response.data;
          tipo_pagosArray.map(function (x) {
            me.tipo_pagos.push({
              text: x.descripcion,
              value: x.idtipo_pago,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion SELECT

    //#region COMPRAS
    listar() {
      let me = this;
      me.loading = true;

      let adcompra = this.$route.params.adcompra;
      let dePedido = this.$route.params.depedido; // true
      let deidtipo_servicio = this.$route.params.deidtipo_servicio;
      let deidpersona = this.$route.params.deidpersona;
      let deidpedido = this.$route.params.deidpedido;
      let dedescto_global = this.$route.params.dedescto_global;
      let deseguro = this.$route.params.deseguro;

      if (adcompra === true) {
        me.verNuevaCompra = 1;
        me.$route.params.adcompra = false;
      }

      if (dePedido == true) {
        me.idtipo_servicio = deidtipo_servicio;
        me.idpersona = deidpersona;
        me.selectProveedor(deidpersona);
        me.idpedido = deidpedido;
        me.descto_global = dedescto_global.toFixed(2);
        me.seguro = deseguro.toFixed(2);
        me.listarDetallePedidos(deidpedido);
        me.verNuevaCompra = 1;
        me.$route.params.depedido = false;
      }

      if (me.buscar == "") {
        axios.get("api/Movimientos/ListarCompra", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            idusuario: parseInt(me.usuario.idusuario),
            buscar: me.buscar == "" ? "-" : me.buscar,
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
          .then(function (response) {
            me.compras = response.data;
            me.loading = false;
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios.get("api/Movimientos/ListarCompraTotal", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            buscar: me.buscar == "" ? "-" : me.buscar,
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
          .then(function (response) {
            me.compras = response.data;
            me.loading = false;
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    guardarCompra() {
      let me = this;
      me.detalle_compras = []; // Limpiamos array para llenarlo
      // Validamos si el detalle de pedido esta vacio
      if (me.detalles.length <= 0) {
        return me.snackBar({ cl: "warning", msg: "Ingrese al menos un producto al detalle." });
      }
      me.convertirDetCompra(); // Convertir detalles a entero y decimal
      me.desabilitar = true; // Desabilitamos boton guardar

      axios.post("api/Movimientos/CrearCompra", {
        act_pprod_comp: !me.datos.actpprodcompra ? "SI" : me.datos.actpprodcompra,
        idsucursal: parseInt(me.usuario.idsucursal),
        idpersona: me.idpersona,
        idusuario: parseInt(me.usuario.idusuario),
        idpedido: me.idpedido,
        idtipo_servicio: me.idtipo_servicio,
        idtipo_pago: me.idtipo_pago,
        idtipo_comprobante: me.idtipo_comprobante,
        serie_comprobante: me.serie_comprobante,
        num_comprobante: me.num_comprobante,
        fecha_comprobante: me.fecha_comprobante,
        igv: me.igv,
        descto_global: me.common.valDec(me.descto_global),
        descto_item_total: me.common.valDec(me.descto_item_total),
        otros_item_total: me.common.valDec(me.otros_item_total),
        seguro: me.common.valDec(me.seguro),
        inafecto: 0,
        exonerado: 0,
        subtotal_gravado: parseFloat(me.subtotal_gravado),
        gratuito: parseFloat(me.gratuito),
        igv_gravado: parseFloat(me.igv_gravado),
        importe_total: parseFloat(me.importe_total),

        detalle_compras: me.detalle_compras,
      })
        .then(function (response) {
          me.ocultarNuevaCompra();
          me.snackBar({ cl: "success", msg: response.data });
          me.listar();
          me.limpiar();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
          me.listar();
          //console.log(error);
        });
    },

    convertirDetCompra() {
      let me = this;
      for (var i = 0; i < me.detalles.length; i++) {
        me.detalle_compras.push({
          idproducto: me.detalles[i].idproducto,
          serie: me.detalles[i].serie,
          stock_ingreso: parseInt(me.detalles[i].stock_ingreso),
          stock_actual: parseInt(me.detalles[i].stock_ingreso),
          valor_unit_compra: parseFloat(me.detalles[i].valor_unit_compra),
          precio_unit_compra: me.igv == 0 ? parseFloat(me.detalles[i].valor_unit_compra) : me.common.roundOut(parseFloat(me.detalles[i].valor_unit_compra) * me.igv + parseFloat(me.detalles[i].valor_unit_compra), 4),
          descto_item: me.common.valDec(me.detalles[i].descto_item),
          otros_item: me.common.valDec(me.detalles[i].otros_item),
          idtipo_afectacion: me.detalles[i].idtipo_afectacion,
        });
      }
    },

    llenarCeroNumComprobante(num_comprobante) {
      this.num_comprobante = num_comprobante.replace(/\w\S*/g, (w) =>
        w.replace(w, (c) => ("00000000" + c).slice(-8))
      );
      //this.num_comprobante = ("00000000" + num_comprobante).slice(-8);
    },

    comprobanteSeleccionado(comprobantes) {
      let arrayComprobante = comprobantes.split(["-"]);
      this.idtipo_comprobante = arrayComprobante[0]; // idtipo_comprobante
      this.comprobante = arrayComprobante[1]; // nombre comprobante

      if (this.idtipo_comprobante == "00") {
        // Si es Recibo interno
        this.disabled = true;
        this.igv = 0;
        this.serie_comprobante = "0000";
        this.num_comprobante = "00000000";
      } else {
        // Si es factura, boleta, guia
        this.disabled = false;
        this.igv = parseFloat(this.datos.igv);
        this.serie_comprobante = this.comprobante.substr(0, 1);
        this.num_comprobante = "";
      }
    },

    mostrarNuevaCompra() {
      this.verNuevaCompra = 1;

    },

    ocultarNuevaCompra() {
      this.verNuevaCompra = 0;
      this.desabilitar = false; // Habilitamos boton guardar pedido y compra
      this.limpiar();
    },

    verDetallesPedidos(item) {
      this.limpiar();
      this.idpedido = item.idpedido;
      this.listarDetallePedidos(item.idpedido);
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;
      this.idtipo_servicio = item.idtipo_servicio;
      this.fecha = item.fecha;
      this.fecha_comprobante = item.fecha_comprobante;
      this.fechaSelectComprob(moment(this.fecha_comprobante).format("YYYY-MM-DD"));
      this.idpersona = item.idpersona;
      this.selectProveedor(item.idpersona);

      this.descto_global = parseFloat(item.descto_global).toFixed(2);
      this.seguro = parseFloat(item.seguro).toFixed(2);

      this.verNuevaCompra = 1;
      this.editedIndex = 1;
    },

    // Detalles de pedido y compra
    listarDetallePedidos(id) {
      let me = this;
      var detallesArray = [];

      axios.get("api/Pedidos/ListarDetallesCompras/" + id)
        .then(function (response) {
          detallesArray = response.data; // recorremos el array de detallesArray de ventas para formatear el precio
          detallesArray.map(function (x) {
            me.detalles.push({
              idproducto: x.idproducto,
              serie: x.serie,
              codigo_producto: x.codigo_producto,
              codigo_producto2: x.codigo_producto2,// cbarra
              idunidad_medida: x.idunidad_medida,
              //categoria: x.categoria,
              descripcion: x.codigo_producto + " " + x.descripcion + (x.serie == "" ? "" : " S/N:" + x.serie) + " " + x.categoria + " " + x.marca,
              stock_ingreso: x.stock_ingreso,
              valor_unit_compra: x.valor_unit_compra,
              precio_unit_compra: x.precio_unit_compra,
              descto_item: x.descto_item,
              otros_item: x.otros_item,
              idtipo_afectacion: x.idtipo_afectacion,
              descripcion_completa: x.descripcion_completa,
              marca: x.marca,// cbarra
              precio_unit_venta: x.precio_unit_venta,// cbarra
              descripcion_barra: x.descripcion,// cbarra
            });
          });
          me.comprobanteModal == 1 ? me.crearPDF() : ""; // Solo para pedido pdf
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    // Eliminar detalle pedido
    eliminarDetallePedido(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    /* Modal Anular */
    anular() {
      let me = this;

      axios.put("api/Movimientos/AnularCompra/" + me.adId + "/" + me.adFecha + "/" + me.usuario.idsucursal + "/" + me.usuario.idusuario)
        .then(function (response) {
          me.snackBar({ cl: "success", msg: response.data });
          me.adModal = 0;
          me.adId = "";
          me.adFecha = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
          me.snackBar({ cl: "error", msg: error.response.data });
          me.adModal = 0;
          me.adId = "";
          me.adFecha = "";
        });
    },

    anularMostrar(item) {
      this.adModal = 1;
      this.adId = item.idmovimiento;
      this.adFecha = item.fecha;
    },

    anularCerrar() {
      this.adModal = 0;
    },
    /* Fin Modal Anular */
    //#endregion COMPRAS

    //#region CUENTAS
    // Cambiar color al evento en calendario
    obtenerEventoColor(event) {
      return event < this.fecha_actual
        ? "error"
        : event == this.fecha_actual
          ? "warning"
          : "success";
    },

    // Listar detalle cuentas por pagar
    cargarEventoCuentaxPagar() {
      let me = this;
      var detalleCxPArray = [];

      axios.get("api/Detalle_Cuentas/ListarDetalleCxP/" + parseInt(me.usuario.idsucursal))
        .then(function (response) {
          detalleCxPArray = response.data;
          detalleCxPArray.map(function (x) {
            me.eventosCxP.push(x.fecha_vencimiento.substr(0, 10));
          });
          //console.log(me.eventosCxP);
        })
        .catch((err) => {
          //console.log(err.response);
        });
    },

    guardarCronograma() {
      let me = this;
      me.detalle_cuentas = [];
      // Validamos detalle
      if (me.cronograma.length <= 0) {
        return me.snackBar({ cl: "warning", msg: "Ingrese al menos una fecha de vencimiento e importe al detalle." });
      }

      me.desabilitar = true;

      // Convetir array cronograma a decimal y formatear fecha
      for (var i = 0; i < me.cronograma.length; i++) {
        me.detalle_cuentas.push({
          fecha_vencimiento: me.fechaConvertida(
            me.cronograma[i].fecha_vencimiento
          ),
          importe: parseFloat(me.cronograma[i].cuota),
          pago_importe: me.cronograma[i].pago_importe,
        });
      }

      // Ordenamos detalle_cuentas por fecha
      me.detalle_cuentas.sort(function (a, b) {
        if (a.fecha_vencimiento > b.fecha_vencimiento) {
          return 1;
        }
        if (a.fecha_vencimiento < b.fecha_vencimiento) {
          return -1;
        }
        return 0;
      });

      axios.post("api/Detalle_Cuentas/Crear", {
        idmovimiento: me.idmovimiento,
        detalle_cuentas: me.detalle_cuentas,
      })
        .then(function (response) {
          me.desabilitar = false;
          me.snackBar({ cl: "success", msg: response.data });
          me.ocultarCronograma();
          me.listar();
        })
        .catch(function (error) {
          me.desabilitar = false;
          me.detalle_cuentas = [];
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    mostrarDetalleCuentas(item) {
      this.idmovimiento = item.idmovimiento;
      this.moneda = item.moneda;
      this.listarDetalleCuentas(item.idmovimiento);
      this.verDetalleCuenta = 1;
    },

    listarDetalleCuentas(id) {
      let me = this;
      axios.get("api/Detalle_Cuentas/ListarDetalles/" + id)
        .then(function (response) {
          me.detalleCuentas = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    ocultarDetalleCuenta() {
      this.verDetalleCuenta = 0;
      this.detalleCuentas = [];
      this.limpiar();
    },

    /* CRONOGRAMA CUENTAS POR PAGAR */
    crearCronograma(item) {
      this.idmovimiento = item.idmovimiento;
      this.proveedor = item.proveedor;
      this.fecha = item.fecha;
      this.importe_total = item.importe_total.toFixed(2);
      this.moneda = item.moneda;
      this.verCronograma = 1;
    },

    agregarDetalleCronograma() {
      this.cronograma.push({
        fecha_vencimiento: this.fecha_cronograma_formateada,
        cuota: this.calcularCuota,
        pago_importe: 0,
      });
    },

    eliminarDetalleConograma(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    ocultarCronograma() {
      this.verCronograma = 0;
      this.importe = 1;
      this.cronograma = [];
      this.detalle_cuentas = [];
      this.plazo = 1;
      this.limpiar();
    },
    /* FIN CRONOGRAMA CUENTAS POR PAGAR */
    //#endregion CUENTAS

    //#region IMPRESIÓN
    savePdf() {
      var blob = this.blobPdf;
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      var fileName = "Compra " + this.comprobante;
      link.download = fileName;
      link.click();
    },

    printExternal() {
      printJS({
        printable: this.stringPdf.replace(
          "data:application/pdf;filename=generated.pdf;base64,",
          ""
        ),
        type: "pdf",
        base64: true,
      });
    },

    mostrarComprobante(item) {
      this.limpiar();
      this.idmovimiento = item.idmovimiento;
      this.sucursal = item.sucursal;
      this.comprobante = item.comprobante;
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;
      this.proveedor = item.proveedor;
      this.num_documento = item.num_documento;
      this.direccion = item.direccion;
      this.telefono = item.telefono;
      this.email = item.email;
      this.fecha = item.fecha;
      this.fecha_comprobante = item.fecha_comprobante;
      this.igv = item.igv;
      this.moneda = item.moneda;
      this.idpedido = item.idpedido;

      this.descto_global = item.descto_global;
      this.descto_item_total = item.descto_item_total;
      this.seguro = item.seguro;
      this.subtotal_gravado = item.subtotal_gravado;
      this.gratuito = item.gratuito;
      this.igv_gravado = item.igv_gravado;
      this.importe_total = item.importe_total;
      this.otros_item_total = item.otros_item_total;

      this.listarDetallePedidos(item.idpedido);
      this.comprobanteModal = 1;
    },

    ocultarComprobante() {
      this.comprobanteModal = 0;
      this.limpiar();
    },
    //#endregion IMPRESIÓN

    //#region PRODUCTO
    buscarCodigoBarra() {
      let me = this;

      axios.get("api/Productos/BuscarCodigoBarraCompra/" + this.codigo_barra)
        .then(function (response) {
          //console.log(response);
          me.agregarDetalle(response.data);
          me.snackBar({ cl: "info", msg: "Se agrego " + response.data.descripcion + " al detalle." });
          me.codigo_barra = "";
        })
        .catch(function (error) {
          //console.log(error);
          me.snackBar({ cl: "warning", msg: "No existe registro de código de barra para el producto." });
          me.codigo_barra = "";
        });
    },

    listarProducto() {
      let me = this;
      axios.get("api/Productos/ListarCompra", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          buscar: me.busqueda_producto == "" ? "-" : me.busqueda_producto,
        },
      })
        .then(function (response) {
          me.productos = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    dblClickRepuesto(event, { item }) {
      this.agregarDetalle(item);
    },

    agregarDetalle(data = []) {
      let me = this;

      me.detalles.push({
        idproducto: data["idproducto"],
        serie: "",
        //codigo_producto: data["codigo_producto"],
        //categoria: data["categoria"],
        //marca: data["marca"],
        idunidad_medida: data["idunidad_medida"],
        descripcion: data["codigo_producto"] + " " + data["descripcion"] + " " + data["categoria"] + " " + data["marca"],
        stock_ingreso: 1,
        valor_unit_compra: me.common.roundOut((me.idtipo_servicio == "3" ? data["precio_unit_compras"] : me.idtipo_servicio == "4" ? data["precio_unit_comprad"] : 0) / (1 + me.igv), 4),
        descto_item: 0,
        otros_item: me.common.roundOut(me.idtipo_servicio == "3" ? data["otross"] : me.idtipo_servicio == "4" ? data["otrosd"] : 0, 4),
        idtipo_afectacion: data["idtipo_afectacion"],
      });
    },

    mostrarProductos() {
      this.verProductos = 1;
    },

    ocultarProductos() {
      this.verProductos = 0;
      this.imagen_zoom = "";
      //this.busqueda_producto = "";
      //this.productos = [];
    },
    
    verImagen(item) {
      this.imagen_zoom = item.imagen;
      this.selectedZoom = true;
    },
    //#endregion PRODUCTO

    //#region CODBARRA
    mostrarBarCode(item) {
      this.adModalBarra = 1;
      this.codigo_barra = item.codigo_barra;
      this.codigo_producto = item.codigo_producto;
      this.codigo_producto2 = item.codigo_producto2;
      this.cantCodigos = item.stock_ingreso;
      this.marca = item.marca;
      this.precio_unit_venta = item.precio_unit_venta;
      this.descripcion_barra = item.descripcion_barra;
    },

    cerrarBarCode() {
      this.adModalBarra = 0;
      this.codigo_barra = "";
      this.codigo_producto = "";
      this.codigo_producto2 = "";
      this.marca = "";
      this.precio_unit_venta = "";
      this.descripcion_barra = "";

      this.cantCodigos = 1;
      document.getElementById("contenedor").innerHTML = ""; // Limpiamos comtenedor de codigo de barras
    },

    async crearBarCode() {
      let me = this;
      const contenedor = document.querySelector("#contenedor");
      // Por cada producto, crear un SVG y adjuntarlo

      //Validamos 
      if (!me.codigo_producto) { return me.snackBar({ cl: "warning", msg: "Tiene que registar el código de producto principal." }); }

      var codigoArray = [];

      for (var i = 0; i < me.cantCodigos; i++) {
        codigoArray.push({
          codigos: me.codigo_producto + " | " + me.codigo_producto2,
          marca_precio: me.marca + " | $" + me.common.roundOut(me.precio_unit_venta / me.tc, 2),
          descripcion: me.descripcion_barra,
          codigo_barra: me.codigo_producto + me.marca,
        });
      };

      codigoArray.forEach((producto) => {
        const elemento = document.createElement("img");
        elemento.dataset.value = producto.codigo_barra;
        elemento.dataset.codigos = producto.codigos;
        elemento.dataset.marca_precio = producto.marca_precio;
        elemento.dataset.descripcion = producto.descripcion;
        elemento.classList.add("codigo_barra");
        contenedor.appendChild(elemento);
      });

      Barcode(".codigo_barra")
        .options({
          format: "CODE128", // El formato
          width: 5, // La anchura de cada barra
          height: 200, // La altura del código
          displayValue: true,
          marginTop: 5, // Margen superior
          marginRight: 5, // Margen derecho
          marginBottom: 5, // Margen inferior
          marginLeft: 5, // Margen izquierdo
          fontSize: 40,
        })
        .init();

      const nodeMap = await document.getElementById("contenedor").childNodes;

      var arrayProducto = [];
      for (let i = 0; i < nodeMap.length; i++) {
        arrayProducto.push({
          codigo_barra: nodeMap[i].dataset.value,
          img: nodeMap[i].currentSrc,
          codigos: nodeMap[i].dataset.codigos,
          marca_precio: nodeMap[i].dataset.marca_precio,
          descripcion: nodeMap[i].dataset.descripcion,
        });
      }

      // Agregar Encabezado: titulo y logo
      const addBody = (doc) => {
        const medio = (doc.internal.pageSize.width / 2) + 10;
        const medio2 = medio / 4;

        const columnWidth = 50; // Ancho de cada columna
        const rowHeight = 35;   // Alto de cada fila
        const numRows = Math.ceil(arrayProducto.length / 4); // Cantidad de filas necesarias

        let salto = 10;

        // Recorrer por filas y columnas
        for (let row = 0; row < numRows; row++) {
          for (let col = 0; col < 4; col++) {
            const index = row * 4 + col;
            if (index >= arrayProducto.length) {
              break; // Si no hay más productos, salir del bucle interno
            }
            doc.setFontSize(7);
            doc.setFont("helvetica", "bold");
            doc.text(me.datos.sucursal, col * columnWidth + medio2, salto, { align: "center" });
            doc.setFont("helvetica", "normal");
            doc.text(arrayProducto[index].codigos, col * columnWidth + medio2, salto + 3, { align: "center" });
            doc.text(arrayProducto[index].marca_precio, col * columnWidth + medio2, salto + 6, { align: "center" });

            // Dividir la descripción en dos líneas
            const descripcion1 = arrayProducto[index].descripcion.slice(0, 25);
            const descripcion2 = arrayProducto[index].descripcion.slice(25, 50);
            doc.text(descripcion1, col * columnWidth + medio2, salto + 9, { align: "center" });
            doc.text(descripcion2, col * columnWidth + medio2, salto + 12, { align: "center" });

            // Imagen Codigo Barra
            doc.addImage(arrayProducto[index].img, "PNG", col * columnWidth + 10, salto + 13, 40, 13.44);
          }
          // Verificar si se necesita una nueva página (más de 7 líneas)
          if (salto + rowHeight > doc.internal.pageSize.height - 10) {
            doc.addPage();
            salto = 10; // Reiniciar el valor de salto en la nueva página
          } else {
            // Incrementar el salto para la próxima fila
            salto += rowHeight;
          }
        }

      };
      var doc = new jsPDF("p", "mm", [210, 297]);

      addBody(doc);
      // Exportar
      //doc.save("CodBarra " + me.codigo_producto + me.marca + ".pdf");
      doc.setProperties({
        title: "Codigo Barra " + me.codigo_producto + me.marca
      });
      doc.output('dataurlnewwindow');

      document.getElementById("contenedor").innerHTML = ""; // Limpiamos comtenedor de codigo de barras
    },

    async crearBarCodeTot() {
      let me = this;
      const contenedor = document.querySelector("#contenedor");
      // Por cada producto, crear un SVG y adjuntarlo
      var codigoArray = [];
      // recorrer array
      for (var i = 0; i < me.detalles.length; i++) {
        for (var j = 0; j < me.detalles[i].stock_ingreso; j++) {
          codigoArray.push({
            codigos: me.detalles[i].codigo_producto + " | " + me.detalles[i].codigo_producto2,
            marca_precio: me.detalles[i].marca + " | $" + me.common.roundOut(me.detalles[i].precio_unit_venta / me.tc, 2),
            descripcion: me.detalles[i].descripcion_barra,
            codigo_barra: me.detalles[i].codigo_producto + me.detalles[i].marca,
          });
        };
      };

      codigoArray.forEach((producto) => {
        const elemento = document.createElement("img");
        elemento.dataset.value = producto.codigo_barra;
        elemento.dataset.codigos = producto.codigos;
        elemento.dataset.marca_precio = producto.marca_precio;
        elemento.dataset.descripcion = producto.descripcion;
        elemento.classList.add("codigo_barra");
        contenedor.appendChild(elemento);
      });

      Barcode(".codigo_barra")
        .options({
          format: "CODE128", // El formato
          width: 5, // La anchura de cada barra
          height: 200, // La altura del código
          displayValue: true,
          marginTop: 5, // Margen superior
          marginRight: 5, // Margen derecho
          marginBottom: 5, // Margen inferior
          marginLeft: 5, // Margen izquierdo
          fontSize: 40,
        })
        .init();

      const nodeMap = await document.getElementById("contenedor").childNodes;

      var arrayProducto = [];
      for (let i = 0; i < nodeMap.length; i++) {
        arrayProducto.push({
          codigo_barra: nodeMap[i].dataset.value,
          img: nodeMap[i].currentSrc,
          codigos: nodeMap[i].dataset.codigos,
          marca_precio: nodeMap[i].dataset.marca_precio,
          descripcion: nodeMap[i].dataset.descripcion,
        });
      }
      // Agregar Encabezado: titulo y logo
      const addBody = (doc) => {
        const medio = (doc.internal.pageSize.width / 2) + 10;
        const medio2 = medio / 4;

        const columnWidth = 50; // Ancho de cada columna
        const rowHeight = 35;   // Alto de cada fila
        const numRows = Math.ceil(arrayProducto.length / 4); // Cantidad de filas necesarias

        let salto = 10;

        // Recorrer por filas y columnas
        for (let row = 0; row < numRows; row++) {
          for (let col = 0; col < 4; col++) {
            const index = row * 4 + col;
            if (index >= arrayProducto.length) {
              break; // Si no hay más productos, salir del bucle interno
            }
            doc.setFontSize(7);
            doc.setFont("helvetica", "bold");
            doc.text(me.datos.sucursal, col * columnWidth + medio2, salto, { align: "center" });
            doc.setFont("helvetica", "normal");
            doc.text(arrayProducto[index].codigos, col * columnWidth + medio2, salto + 3, { align: "center" });
            doc.text(arrayProducto[index].marca_precio, col * columnWidth + medio2, salto + 6, { align: "center" });

            // Dividir la descripción en dos líneas
            const descripcion1 = arrayProducto[index].descripcion.slice(0, 25);
            const descripcion2 = arrayProducto[index].descripcion.slice(25, 50);
            doc.text(descripcion1, col * columnWidth + medio2, salto + 9, { align: "center" });
            doc.text(descripcion2, col * columnWidth + medio2, salto + 12, { align: "center" });

            // Imagen Codigo Barra
            doc.addImage(arrayProducto[index].img, "PNG", col * columnWidth + 10, salto + 13, 40, 13.44);
          }
          // Verificar si se necesita una nueva página (más de 7 líneas)
          if (salto + rowHeight > doc.internal.pageSize.height - 10) {
            doc.addPage();
            salto = 10; // Reiniciar el valor de salto en la nueva página
          } else {
            // Incrementar el salto para la próxima fila
            salto += rowHeight;
          }
        }
      };
      var doc = new jsPDF("p", "mm", [210, 297]);

      addBody(doc);
      // Exportar
      doc.save("Codigo Barra " + me.serie_comprobante + "-" + me.num_comprobante + ".pdf");

      document.getElementById("contenedor").innerHTML = ""; // Limpiamos comtenedor de codigo de barras
    },
    //#endregion CODBARRA

    limpiar() {
      this.idmovimiento = "";
      this.moneda = "";
      this.idtipo_servicio = 4; // Compra ME
      this.idpersona = "";
      this.proveedor = "";
      this.num_documento = "";
      this.comprobante = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";
      this.detalles = [];
      this.detalle_compras = [];
      this.idpedido = 0;

      this.descto_global = 0;
      this.descto_item_total = 0;
      this.seguro = 0;
      this.subtotal_gravado = 0;
      this.gratuito = 0;
      this.igv_gravado = 0;
      this.importe_total = 0;

      this.idtipo_pago = "";
      this.idtipo_comprobante = "";
      this.comprobante = "";
      this.comprobantes = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";
      this.disabled = true;

      this.fecha_comprobante = moment().format("YYYY-MM-DD"); // X defecto
      this.fechaSelectComprob(this.fecha_comprobante);

      this.stringPdf = ""; // pdf que se visualiza
      this.srcPdf = "";
      this.blobPdf = "";

      this.editedIndex = -1;
    },

    //#region PERSONA
    extraerDocumento(num_documento) {
      let me = this;
      me.desabilitarBuscar = true;

      var token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpmcmFpbkBob3RtYWlsLmVzIn0.tT4tLoVKqPHNbwZsFbim_mUkJkz5zZtseefC0s7RBFs";
      if (me.idtipo_documento_pers == 1) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/dni/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = "000000";
              me.selectUbigeo(me.idubigeo);
              me.nombre =
                data.apellidoPaterno +
                " " +
                data.apellidoMaterno +
                " " +
                data.nombres;
            } else {
              me.nombre = "";
              me.snackBar({ cl: "info", msg: data.message }); // Sin resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.snackBar({ cl: "error", msg: "Error en el Nº DNI." });
          });
      } else if (me.idtipo_documento_pers == 6) {
        axios.post("api/Personas/Padron", {
          ruc: me.num_documento,
        })
          .then(function (response) {
            me.desabilitarBuscar = false;
            me.idubigeo = response.data.ubigeo;
            me.selectUbigeo(me.idubigeo);
            me.nombre = response.data.razon;
            me.direccion =
              response.data.direccion == null
                ? ""
                : response.data.direccion.trim();
            me.snackBar({ cl: "info", msg: "Contribuyente con estado " + response.data.estado + " y condición " + response.data.condicion });
          })
          .catch(function (error) {
            me.desabilitarBuscar = false;
            me.nombre = "";
            me.direccion = "";
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    guardarPersona() {
      let me = this;
      me.desabilitarPer = true;

      axios.post("api/Personas/Crear", {
        tipo_persona: "Cliente",
        nombre: me.nombre,
        idtipo_documento: me.idtipo_documento_pers,
        num_documento: me.num_documento,
        idubigeo: me.idubigeo,
        direccion: me.direccion,
        telefono: me.telefono,
        email: me.email,
      })
        .then((response) => {
          me.idpersona = response.data.idpersona;
          me.selectProveedor(me.idpersona);
          me.desabilitarPer = false;
          me.snackBar({ cl: "success", msg: response.data.mensaje });
          me.closePersona();
        })
        .catch(function (error) {
          me.desabilitarPer = false;
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    mostrarPersona() {
      this.verPersona = true;
    },

    closePersona() {
      this.verPersona = false;
      this.limpiarPersona();
    },

    limpiarPersona() {
      this.idtipo_documento_pers = "";
      this.num_documento = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.nombre = "";
      this.direccion = "";
      this.email = "";
      this.telefono = "";
    },
    //#endregion PERSONA

    crearPDF() {
      var columns = [
        { header: "CANT.", dataKey: "stock_ingreso" },
        { header: "UNID.", dataKey: "idunidad_medida" },
        { header: "DESCRIPCIÓN", dataKey: "descripcion" },
        { header: "VALOR UNIT.", dataKey: "valor_unit_compra" },
        { header: "PREC. UNIT.", dataKey: "precio_unit_compra" },
        { header: "DSCTO", dataKey: "descto_item" },
        { header: "OTROS", dataKey: "otros_item" },
        { header: "VALOR VTA", dataKey: "subtotal" },
      ];
      var body = [];
      var foot = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          stock_ingreso: this.detalles[i].stock_ingreso,
          idunidad_medida: this.detalles[i].idunidad_medida,
          descripcion: this.detalles[i].descripcion,
          valor_unit_compra: numeral(this.detalles[i].valor_unit_compra).format("###,##0.0000"),
          precio_unit_compra: numeral(this.detalles[i].precio_unit_compra).format("###,##0.0000"),
          descto_item: numeral(this.detalles[i].descto_item).format("###,##0.0000"),
          otros_item: numeral(this.detalles[i].otros_item).format("###,##0.0000"),

          subtotal: numeral(
            this.common.roundOut(this.detalles[i].valor_unit_compra * this.detalles[i].stock_ingreso - this.detalles[i].descto_item, 2)).format("###,##0.00")
        });

        if (i === this.detalles.length - 1) {
          foot.push(
            [
              {
                content: "Descuento Total " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 7,
              },
              numeral(this.descto_global + this.descto_item_total).format("###,##0.00"),
            ],
            [
              {
                content: "Seguro " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 7,
              },
              numeral(this.seguro).format("###,##0.00"),
            ],
            [
              {
                content: "Gravada " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 7,
              },
              numeral(this.subtotal_gravado).format("###,##0.00"),
            ],
            [
              {
                content: "Gratuito " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 7,
              },
              numeral(this.gratuito).format("###,##0.00"),
            ],
            [
              {
                content: "IGV " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 7,
              },
              numeral(this.igv_gravado).format("###,##0.00"),
            ],
            [
              {
                content: "Importe Total " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 7,
              },
              numeral(this.importe_total).format("###,##0.00"),
            ],
            [
              {
                content: "Otros Total " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 7,
              },
              numeral(this.otros_item_total).format("###,##0.00"),
            ]
          );
        }
      }

      //console.log(body);
      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 70, 49.39);
          // Datos empresa
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(this.datos.empresa, 140, 28);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(8);
          doc.text(this.datos.dirsucursal, 140, 38);
          doc.text("Telf.: " + this.datos.telsucursal, 140, 48);
          doc.text("E-Mail: " + this.datos.mailsucursal, 140, 58);
          // Fin Datos empresa

          // Datos proveedor
          doc.text("Proveedor: " + this.proveedor, 40, 75);
          doc.text("Ruc N°: " + this.num_documento, 40, 90);

          doc.text("Establecimiento: " + this.datos.sucursal, 360, 75);
          doc.text(
            "Fecha: " + moment(this.fecha).format("DD/MM/YYYY"),
            360,
            90
          );
          doc.text(
            "Fecha Comprob.: " +
            moment(this.fecha_comprobante).format("DD/MM/YYYY"),
            450,
            90
          );

          // Fin Datos proveedor

          // Datos documento
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(
            this.comprobante,
            doc.internal.pageSize.width / 2 + 140,
            42,
            "center"
          );
          doc.text(
            this.serie_comprobante + "-" + this.num_comprobante,
            doc.internal.pageSize.width / 2 + 140,
            54,
            "center"
          );
          // Fin Datos documento
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            "center"
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt", [595, 842]);

      doc.autoTable({
        //theme: "grid",
        //tableLineWidth: 1,
        columns,
        body,
        foot,
        margin: { top: 100 },
        //styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          stock_ingreso: { cellWidth: 31, halign: "center" },
          idunidad_medida: { cellWidth: 40, halign: "center" },
          descripcion: { cellWidth: "auto" },
          valor_unit_compra: { cellWidth: 54, halign: "right" },
          precio_unit_compra: { cellWidth: 54, halign: "right" },
          descto_item: { cellWidth: 54, halign: "right" },
          otros_item: { cellWidth: 54, halign: "right" },
          subtotal: { cellWidth: 54, halign: "right" },
        },
      });
      // 515 ancho hoja
      doc.setProperties({
        title: this.comprobante,
      });
      addHeaders(doc);
      addFooters(doc);
      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
      // Para visualizar todas las paginas del pdf
      this.srcPdf = pdf.createLoadingTask(this.stringPdf);
      this.srcPdf.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
  },
};
</script>
<style scoped>
.right-input>>>input {
  text-align: right;
}

.center-input>>>input {
  text-align: center;
}
</style>