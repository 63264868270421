<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout alig-start>
      <v-flex>
        <!-- Listado de empresa -->
        <v-data-table :loading="loading" :headers="headers" :items="empresa" disable-pagination hide-default-footer
          class="elevation-3">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>EMPRESA</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- Modificar empresa -->
              <v-dialog v-model="dialog" max-width="500px" persistent>
                <v-card>
                  <v-toolbar dark dense class="primary" flat>
                    <v-toolbar-title>Actualizar Empresa</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-row class="mt-2">
                        <v-col cols="12" align="center">
                          <template color="teal" v-if="file">
                            <v-layout justify-center>
                              <v-img max-width="100" :src="urlTemporal" @click="$refs.boton.click()" />
                            </v-layout>
                          </template>
                          <template color="teal" v-else>
                            <v-layout justify-center>
                              <v-img max-width="100" :src="'data:image/png;base64,' + logo"
                                @click="$refs.boton.click()" />
                            </v-layout>
                          </template>
                          <input type="file" id="file" ref="boton" accept=".png, .jpg, .jpeg"
                            @change="buscarImagen($event)" class="d-none" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field dense v-model.trim="nombre" label="Nombre"
                            :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 3 && v.length <= 70) || 'Nombre debe tener entre 3 a 70 caracteres', Rules.unespacio]"
                            maxlength="70" prepend-inner-icon="business" v-uppercase hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field dense v-model.trim="nombre_comercial" label="Nombre Comercial"
                            :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 3 && v.length <= 70) || 'Nombre debe tener entre 3 a 70 caracteres', Rules.unespacio]"
                            maxlength="70" prepend-inner-icon="business" v-uppercase hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field dense v-model.trim="igv" label="IGV" :rules="[v => !!v || 'Requerido']"
                            maxlength="5" @keypress="common.numDec($event, igv, 2)" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field dense v-model.trim="cambio" label="Tipo Cambio" :rules="[v => !!v || 'Requerido']"
                            maxlength="7" @keypress="common.numDec($event, cambio, 4)" hide-details>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field dense v-model.trim="ruc" label="RUC"
                            :rules="[v => !!v || 'Requerido', v => (!!v && v.length == 11) || 'El RUC debe tener 11 caracteres']"
                            maxlength="11" @keypress="common.isNum($event)" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field dense v-model.trim="direccion" label="Dirección"
                            :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 100) || 'Dirección debe tener entre 3 a 100 caracteres', Rules.unespacio]"
                            maxlength="60" prepend-inner-icon="place" v-uppercase hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo"
                            v-model="idubigeo" :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                            prepend-inner-icon="pin_drop" clearable hide-details></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-text-field dense v-model.trim="email" label="Email"
                            :rules="[v => /.+@.+\..+/.test(v) || 'El email debe ser válido', Rules.sinespacio]"
                            maxlength="50" prepend-inner-icon="mail" v-lowercase hide-details disabled></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field dense v-model.trim="password_email" label="Password Email"
                            :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 20) || 'Password de Email debe tener min. 3 y max. 20 caracteres']"
                            maxlength="20" :append-icon="showPasswordEmail ? 'visibility' : 'visibility_off'"
                            :type="showPasswordEmail ? 'text' : 'password'"
                            @click:append="showPasswordEmail = !showPasswordEmail" disabled></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field dense v-model.trim="usuario_sol" label="Usuario SOL"
                            :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 20) || 'Usuario SOL debe tener min. 3 y max. 20 caracteres']"
                            maxlength="20" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field dense v-model.trim="password_sol" label="Password SOL"
                            :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 20) || 'Password SOL debe tener min. 3 y max. 20 caracteres']"
                            maxlength="20" :append-icon="showPasswordSol ? 'visibility' : 'visibility_off'"
                            :type="showPasswordSol ? 'text' : 'password'"
                            @click:append="showPasswordSol = !showPasswordSol" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field dense v-model.trim="password_certificado" label="Password Certificado"
                            :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 20) || 'Password Certificado debe tener min. 3 y max. 20 caracteres']"
                            maxlength="20" :append-icon="showPasswordCertificado ? 'visibility' : 'visibility_off'"
                            :type="showPasswordCertificado ? 'text' : 'password'"
                            @click:append="showPasswordCertificado = !showPasswordCertificado"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea dense v-model.trim="eslogan" label="Eslogan"
                            :rules="[v => !!v || 'Eslogan requerido', v => (!!v && v.length >= 3 && v.length <= 180) || 'Eslogan debe tener min. 3 y max. 180 caracteres', Rules.unespacio]"
                            maxlength="180" rows="2" v-uppercase hide-details></v-textarea>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field dense v-model.trim="ctas_banco" label="Ctas Bancos"
                            :rules="[v => v.length <= 70 || 'Ctas no debe tener mas de 70 caracteres', Rules.unespacio]"
                            maxlength="70" v-uppercase hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header>Color Aplicación</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <div class="d-flex justify-center align-center">
                                  <v-color-picker v-model="pcolor" hide-mode-switch></v-color-picker>
                                  <!-- <v-color-picker v-model="pcolor" mode="hexa" elevation="1" hide-inputs hide-mode-switch></v-color-picker> -->
                                </div>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 px-4 justify-center">
                    <v-btn color="error" @click="close" small outlined>
                      <v-icon left>close</v-icon>Cancelar
                    </v-btn>
                    <v-btn color="primary" @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                      small outlined>
                      <v-icon left>check</v-icon>Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modificar empresa -->
            </v-toolbar>
          </template>
          <template v-slot:item.igv="{ item }">
            <span>{{ item.igv * 100 }}%</span>
          </template>
          <template v-slot:item.cambio="{ item }">
            <span>{{ item.cambio.toFixed(4) }}</span>
          </template>

          <!-- Opción -->
          <template v-slot:item.logo="{ item }">
            <template>
              <img height="30px" :src="'data:image/png;base64,' + item.logo" />
            </template>
          </template>
          <template v-slot:item.opciones="{ item }">
            <v-btn small icon>
              <v-icon color="primary" @click="editItem(item)">edit</v-icon>
            </v-btn>
          </template>
          <!-- Fin Opción -->
        </v-data-table>
        <!-- Fin Listado de empresa -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      empresa: [],
      dialog: false,
      headers: [
        { text: "Logo", value: "logo", sortable: false },
        { text: "RAZÓN SOCIAL", value: "nombre", sortable: false },
        { text: "IGV", value: "igv", sortable: false },
        { text: "T/C", value: "cambio", sortable: false },
        { text: "RUC", value: "ruc", sortable: false },
        { text: "DIRECCIÓN", value: "direccion", sortable: false },
        { text: "EMAIL", value: "email", sortable: false },
        { text: "OPC.", value: "opciones", sortable: false, width: 50 },
      ],
      idempresa: "",
      buscar_ubigeo: "",

      idubigeo: "",
      ubigeos: [],

      nombre: "",
      nombre_comercial: "",
      eslogan: "",
      pcolor: "",
      ctas_banco: "",
      igv: "",
      cambio: "",
      ruc: "",
      direccion: "",
      email: "",
      logo: "",
      password_email: "",
      usuario_sol: "",
      password_sol: "",
      password_certificado: "",

      showPasswordEmail: false,
      showPasswordCertificado: false,
      showPasswordSol: false,

      file: null,
      urlTemporal: "",

      adModal: 0,
      adAccion: 0,
      adNombre: "",
      adId: "",

      desabilitar: false,

      isValid: true,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {},

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
  },

  methods: {
    ...mapActions(["cargarSettings", "snackBar"]),

    listar() {
      let me = this;
      me.loading = true;

      axios.get("api/Empresas/Listar")
        .then(function (response) {
          //console.log(response);
          me.empresa = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios.get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios.get("api/Ubigeos/SelectUbigeosFiltro", {
          params: {
            buscar:
              me.buscar_ubigeo == ""
                ? "-"
                : me.buscar_ubigeo == null
                  ? "-"
                  : me.buscar_ubigeo,
          },
        })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    guardar() {
      // Editar
      let me = this;
      me.desabilitar = true;

      axios.put("api/Empresas/Actualizar", {
        idempresa: me.idempresa,
        idubigeo: me.idubigeo,
        nombre: me.nombre,
        nombre_comercial: me.nombre_comercial,
        igv: me.igv,
        cambio: me.cambio,
        ruc: me.ruc,
        direccion: me.direccion,
        email: me.email,
        logo: me.logo,
        eslogan: me.eslogan,
        color: me.pcolor,
        ctas_banco: me.ctas_banco,
        // FE
        password_email: me.password_email,
        password_certificado: me.password_certificado,
        usuario_sol: me.usuario_sol,
        password_sol: me.password_sol,
      })
        .then(function (response) {
          me.desabilitar = false;
          me.snackBar({ cl: "success", msg: response.data });
          me.cargarSettings();
          me.close();
          me.listar();
          me.limpiar();
        })

        .catch(function (error) {
          me.desabilitar = false;
          me.snackBar({ cl: "error", msg: error.response.data });
          me.listar();
        });
    },

    editItem(item) {
      this.idempresa = item.idempresa;
      this.idubigeo = item.idubigeo;
      this.selectUbigeo(item.idubigeo);
      this.nombre = item.nombre;
      this.nombre_comercial = item.nombre_comercial;
      this.ruc = item.ruc;
      this.igv = item.igv;
      this.cambio = item.cambio;
      this.direccion = item.direccion;
      this.email = item.email;
      this.logo = item.logo;
      this.pcolor = item.color;
      this.eslogan = item.eslogan;
      this.ctas_banco = item.ctas_banco;
      this.password_email = item.password_email;
      this.usuario_sol = item.usuario_sol;
      this.password_sol = item.password_sol;
      this.password_certificado = item.password_certificado;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.limpiar();
    },

    limpiar() {
      this.idempresa = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.nombre = "";
      this.nombre_comercial = "";
      this.igv = "";
      this.cambio = "";
      this.ruc = "";
      this.direccion = "";
      this.email = "";
      this.logo = "";
      this.eslogan = "";
      this.pcolor = "";
      this.ctas_banco = "";
      this.file = null;
      this.urlTemporal = "";
      this.showPasswordEmail = false;
      this.showPasswordCertificado = false;
      this.showPasswordSol = false;
      this.mensajeEmpresa = "";

      // Limpiamos input carga de imagen
      document.getElementById("file").value = [];
    },

    buscarImagen(event) {
      //console.log(event.target.files[0]);
      const fileCargado = event.target.files[0];
      // Verificamos si tenemos imagen en fileCargado
      if (fileCargado) {
        const reader = new FileReader();
        reader.readAsDataURL(fileCargado);
        reader.onload = (e) => {
          this.urlTemporal = e.target.result; // Visualizamos en Base64
        };

        // Reducimos imagen con un ancho de 200px
        var resizeImage = function (settings) {
          var file = settings.file;
          var maxSize = settings.maxSize;
          var reader = new FileReader();
          var image = new Image();
          var canvas = document.createElement("canvas");
          var dataURItoBlob = function (dataURI) {
            var bytes =
              dataURI.split(",")[0].indexOf("base64") >= 0
                ? atob(dataURI.split(",")[1])
                : unescape(dataURI.split(",")[1]);
            var mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
            var max = bytes.length;
            var ia = new Uint8Array(max);
            for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
            return new Blob([ia], { type: mime });
          };
          var resize = function () {
            var width = image.width;
            var height = image.height;
            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
            } else {
              if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL("image/jpeg");
            //return dataURItoBlob(dataUrl);
            return dataUrl;
          };
          return new Promise(function (ok, no) {
            if (!file.type.match(/image.*/)) {
              no(new Error("Not an image"));
              return;
            }
            reader.onload = function (readerEvent) {
              image.onload = function () {
                return ok(resize());
              };
              image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
          });
        };
        // llamamos a funcion para reducir imagen a imagen Blob
        resizeImage({
          file: fileCargado,
          maxSize: 200,
        })
          .then((resizedImage) => {
            this.logo = resizedImage.replace("data:image/jpeg;base64,", ""); // Cargamos imagen reducida para guardar y reemplazamos data... x un valor vacio
          })
          .catch((err) => {
            console.error(err);
          });
        // Fin Reducimos imagen con un ancho de 700px
      }
    },
  },
};
</script>