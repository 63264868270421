import { render, staticRenderFns } from "./Ajuste_Stock.vue?vue&type=template&id=bf009bde&scoped=true"
import script from "./Ajuste_Stock.vue?vue&type=script&lang=js"
export * from "./Ajuste_Stock.vue?vue&type=script&lang=js"
import style0 from "./Ajuste_Stock.vue?vue&type=style&index=0&id=bf009bde&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf009bde",
  null
  
)

export default component.exports