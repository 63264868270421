<template>
    <v-card>
        <v-card-text grid-list-sm>
            <v-form v-model="isValid">
                <v-row>
                    <v-col cols="6" md="2" sm="3">
                        <v-select dense v-model="idtipo_servicio" :items="tipo_servicios" label="Moneda"
                            :rules="[v => !!v || 'Servicio requerido']" @change="detalles = []" hide-details></v-select>
                    </v-col>
                    <v-col cols="6" md="2" sm="2">
                        <v-menu v-model="menuFecha" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field dense v-model="fecha_comprobante_formateada" label="Fecha Comprobante"
                                    :rules="[v => !!v || 'Fecha Requerido']" readonly v-on="on"
                                    hide-details></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_comprobante" @input="menuFecha = false"
                                @change="fechaSelectComprob(fecha_comprobante)"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="2" sm="2">
                        <v-select dense v-model="comprobantes" :items="tipo_comprobantes" label="Tipo Comprobante"
                            :rules="[v => !!v || 'Comprobante requerido']"
                            @change="comprobanteSeleccionado(comprobantes)" hide-details></v-select>
                    </v-col>
                    <v-col cols="3" md="1" sm="1">
                        <v-text-field dense v-model="serie_comprobante" label="Serie"
                            :rules="[v => v.length == 4 || 'Requerido', Rules.sinespacio]" :disabled="disabled"
                            maxlength="4" v-uppercase hide-details></v-text-field>
                    </v-col>
                    <v-col cols="9" md="5" sm="4">
                        <v-text-field dense v-model.trim="num_comprobante" label="Núm. Comprobante"
                            :rules="[v => !!v || 'Requerido', Rules.unespacio]"
                            @blur.prevent="llenarCeroNumComprobante(num_comprobante)" maxlength="35"
                            :disabled="disabled" v-numspace hide-details></v-text-field>
                    </v-col>

                    <v-col cols="12" md="9" sm="8">
                        <v-autocomplete dense @keyup="selectProveedor()" :search-input.sync="buscar_proveedor"
                            v-model="idpersona" :items="proveedores" label="Proveedor"
                            :rules="[v => !!v || 'Proveeedor requerido']" clearable hide-details></v-autocomplete>
                    </v-col>
                    <v-col cols="10" md="3" sm="4">
                        <v-select dense v-model="idtipo_pago" :items="tipo_pagos" label="Tipo Pago"
                            :rules="[v => !!v || 'Pago requerido']" hide-details></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-data-table
                            :headers="numserie == false ? cabeceraDetallesCompra : cabeceraDetallesCompraSerie"
                            :items="detalles" disable-pagination hide-default-footer dense class="elevation-1">
                            <template v-slot:header.descripcion="{ header }">
                                {{ header.text }}<v-icon class="ml-2" @click="numserie = !numserie">pin</v-icon>
                            </template>
                            <template v-slot:item.num="{ item }">
                                <td>{{ detalles.indexOf(item) + 1 }}</td>
                            </template>
                            <template v-slot:item.borrar="{ item }">
                                <v-icon small color="error" @click="eliminarDetallePedido(detalles, item)">
                                    delete_forever</v-icon>
                            </template>
                            <template v-slot:item.serie="{ item }">
                                <v-text-field dense type="text" v-model.trim="item.serie" maxlength="80" hide-details>
                                </v-text-field>
                            </template>
                            <template v-slot:item.stock_ingreso="{ item }">
                                <v-text-field dense type="text" @keyup.up="item.stock_ingreso++"
                                    @keyup.down="item.stock_ingreso--" v-model.number="item.stock_ingreso"
                                    :rules="[v => (!!v && v > 0) || 'Requerido']" maxlength="4"
                                    @keypress="common.isNum($event)" onClick="this.select()" class="center-input"
                                    hide-details></v-text-field>
                            </template>
                            <template v-slot:item.valor_unit_compra="{ item }">
                                <v-text-field dense type="text" v-model="item.valor_unit_compra"
                                    :rules="[v => !!v || 'Requerido', item.valor_unit_compra > 0 || 'Error']"
                                    maxlength="10" @keypress="common.numDec($event, item.valor_unit_compra, 4)"
                                    onClick="this.select()" hide-details></v-text-field>
                            </template>
                            <template v-slot:item.descto_item="{ item }">
                                <v-text-field dense type="text" v-model="item.descto_item" maxlength="10"
                                    @keypress="common.numDec($event, item.descto_item, 4)" onClick="this.select()"
                                    hide-details></v-text-field>
                            </template>
                            <template v-slot:item.otros_item="{ item }">
                                <v-text-field dense type="text" v-model="item.otros_item" maxlength="10"
                                    @keypress="common.numDec($event, item.otros_item, 4)" onClick="this.select()"
                                    hide-details></v-text-field>
                            </template>

                            <template v-slot:item.subtotal="{ item }">
                                <div class="text-right">
                                    <span class="font-weight-bold">{{
                                        common.roundOut((common.valInt(item.stock_ingreso) *
                                            common.valDec(item.valor_unit_compra) -
                                            common.valDec(item.descto_item)), 2)
                                        | formatearNumero
                                    }}</span>
                                </div>
                            </template>
                        </v-data-table>
                        <v-container>
                            <v-row no-gutters align="end" justify="end">
                                <v-col cols="10" align="end">
                                    <strong>Descuento Global (-) {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field dense v-model="descto_global" maxlength="7"
                                        @keypress="common.numDec($event, descto_global, 2)" onClick="this.select()"
                                        class="right-input" hide-details></v-text-field>
                                </v-col>
                                <v-col cols="10" align="end">
                                    <strong>Descuento por Item (-) {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field dense disabled
                                        v-model="descto_item_total = common.roundOut(common.valDec(calcularDescuentoItem), 2).toFixed(2)"
                                        class="right-input" hide-details></v-text-field>
                                </v-col>
                                <v-col cols="10" align="end">
                                    <strong>Seguro {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field dense v-model="seguro" maxlength="7"
                                        @keypress="common.numDec($event, seguro, 2)" onClick="this.select()"
                                        class="right-input" hide-details></v-text-field>
                                </v-col>
                                <v-col cols="10" align="end">
                                    <strong>Gravada {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field dense disabled
                                        v-model="subtotal_gravado = common.roundOut((common.valDec(calcularTotal) - common.valDec(descto_global) + common.valDec(seguro)), 2).toFixed(2)"
                                        class="right-input" hide-details></v-text-field>
                                </v-col>
                                <v-col cols="10" align="end">
                                    <strong>IGV {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field dense disabled
                                        v-model="igv_gravado = common.roundOut(((common.valDec(calcularTotal) - common.valDec(descto_global) + common.valDec(seguro)) * igv), 2).toFixed(2)"
                                        class="right-input" hide-details></v-text-field>
                                </v-col>
                                <v-col cols="10" align="end">
                                    <strong>Importe Total {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field dense disabled
                                        v-model="importe_total = common.roundOut((common.valDec(subtotal_gravado) + common.valDec(igv_gravado)), 2).toFixed(2)"
                                        :rules="[v => v > 0 || 'Importe mayor a cero']" class="right-input"
                                        hide-details>
                                    </v-text-field>
                                </v-col>
                                <!-- <v-col cols="10" align="end">
                                    <strong>Gratuita {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field dense disabled
                                        v-model="gratuito = common.roundOut(calcularGratuitoTotal, 2).toFixed(2)"
                                        class="right-input" hide-details></v-text-field>
                                </v-col> -->
                                <v-col cols="10" align="end">
                                    <strong>Otros Item {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field dense disabled
                                        v-model="otros_item_total = common.roundOut(common.valDec(calcularOtrosItem), 2).toFixed(2)"
                                        class="right-input" hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions class="py-0 pb-4 px-4 justify-center">
            <v-btn color="error" @click="ocultarNuevaCompra()" small outlined>
                <v-icon left>close</v-icon>Limpiar
            </v-btn>
            <v-btn color="primary" @click="guardarCompra()" :loading="desabilitar" :disabled="desabilitar || !isValid"
                small outlined>
                <v-icon left>check</v-icon>Crear Compra
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
    props: ['changeIdProduct'],
    data() {
        return {
            compras: [],
            cabeceraDetallesCompra: [
                { text: "", value: "borrar", sortable: false },
                { text: "#", value: "num", align: "center", sortable: false },
                { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
                { text: "SERIE", value: "serie", sortable: false, align: ' d-none' },
                { text: "CANT.", value: "stock_ingreso", align: "center", sortable: false },
                { text: "P/V/UNIT.", value: "valor_unit_compra", sortable: false },
                { text: "DSCTO", value: "descto_item", sortable: false },
                { text: "OTROS", value: "otros_item", sortable: false },
                { text: "TOTAL", value: "subtotal", sortable: false },
            ],
            cabeceraDetallesCompraSerie: [
                { text: "", value: "borrar", sortable: false },
                { text: "#", value: "num", align: "center", sortable: false },
                { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
                { text: "SERIE", value: "serie", sortable: false },
                { text: "CANT.", value: "stock_ingreso", align: "center", sortable: false },
                { text: "P/V/UNIT.", value: "valor_unit_compra", sortable: false },
                { text: "DSCTO", value: "descto_item", sortable: false },
                { text: "OTROS", value: "otros_item", sortable: false },
                { text: "TOTAL", value: "subtotal", sortable: false },
            ],
            numserie: false,
            buscar_proveedor: "",
            buscar_ubigeo: "",
            codigo_barra: "",
            busqueda_producto: "",
            productos: [],

            detalles: [],
            detalleCuentas: [],
            buscar: "",
            idmovimiento: "",

            idpedido: 0,
            idpersona: "",
            proveedores: [],
            idtipo_servicio: 4, // Compra ME
            tipo_servicios: [],

            igv: 0,
            tc: 0,

            importe: 0,
            descto_global: 0,
            descto_item_total: 0,
            otros_item_total: 0,
            seguro: 0,
            subtotal_gravado: 0,
            gratuito: 0,
            igv_gravado: 0,
            importe_total: 0,

            verProductos: 0,

            selectedZoom: false,
            imagen_zoom: "",

            fecha_comprobante_formateada: "",
            menuFecha: false,

            sucursal: "",
            proveedor: "",
            fecha: "",
            fecha_comprobante: moment().format("YYYY-MM-DD"),

            moneda: "",

            //idtipo_documento: "",
            adModal: 0,
            adId: "",
            adFecha: "",

            desabilitar: false, // Guardar
            isValid: true, // validamos
            disabled: true, // Serie y numero comprobante

            idtipo_pago: "",
            tipo_pagos: [],
            idtipo_comprobante: "",
            comprobante: "",
            tipo_comprobantes: [],
            comprobantes: "",
            serie_comprobante: "",
            num_comprobante: "",

            // Persona
            nombre: "",
            idtipo_documento_pers: "",
            tipo_documentos_cli: [],
            num_documento: "",
            idubigeo: "",
            ubigeos: [],
            direccion: "",
            telefono: "",
            email: "",
            verPersona: false,
            desabilitarBuscar: false,
            desabilitarPer: false,
            isValidPer: true,
            // Persona

            verCronograma: 0,
            verDetalleCuenta: 0,

            cronograma: [],
            detalle_cuentas: [],


            plazo: "1",
            fecha_cronograma_formateada: "",
            fecha_cronograma: moment().format("YYYY-MM-DD"),
            fecha_actual: moment().format("YYYY-MM-DD"),

            dates: [
                moment().startOf("year").format("YYYY-MM-DD"),
                moment().endOf("year").format("YYYY-MM-DD"),
            ],

            dialogProduct: false,

            codigo_producto: "",
            // barcode
            adModalBarra: 0,
            cantCodigos: 0,
            marca: "",
            // fin barcode

            /* Validaciones */
            Rules: {
                sinespacio: (v) =>
                    (v || "").indexOf(" ") < 0 || "No se permite espacios",
                unespacio: (v) =>
                    (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
            },
        }
    },
    computed: {
        ...mapState(["usuario", "datos", "logo"]),

        dateRangeText() {
            let fechaIni;
            let fechaFin;
            for (var i = 0; i < this.dates.length; i++) {
                fechaIni = this.common.formatDate(this.dates[0]);
                fechaFin = this.common.formatDate(this.dates[1]);
            }
            let arrayFechas = [fechaIni, fechaFin];
            return arrayFechas.join(" ~ ");
        },

        calcularTotal: function () {
            let me = this;
            var resultado = 0.0;
            for (var i = 0; i < me.detalles.length; i++) {
                if (me.detalles[i].idtipo_afectacion == "10") {
                    resultado = resultado + me.common.roundOut(me.common.valInt(me.detalles[i].stock_ingreso) * me.common.valDec(me.detalles[i].valor_unit_compra) - me.common.valDec(me.detalles[i].descto_item), 4);
                }
            }
            return resultado;
        },

        calcularGratuitoTotal: function () {
            let me = this;
            var resultado = 0.0;
            for (var i = 0; i < me.detalles.length; i++) {
                if (me.detalles[i].idtipo_afectacion == "31") {
                    resultado = resultado + me.common.roundOut(me.common.valInt(me.detalles[i].stock_ingreso) * me.common.valDec(me.detalles[i].valor_unit_compra) - me.common.valDec(me.detalles[i].descto_item), 4);
                }
            }
            return resultado;
        },

        calcularDescuentoItem: function () {
            let me = this;
            var resultado = 0.0;
            for (var i = 0; i < me.detalles.length; i++) {
                resultado = resultado + me.common.roundOut(me.common.valDec(me.detalles[i].descto_item), 4);
            }
            return resultado;
        },

        calcularOtrosItem: function () {
            let me = this;
            var resultado = 0.0;
            for (var i = 0; i < me.detalles.length; i++) {
                resultado = resultado + me.common.roundOut(me.common.valInt(me.detalles[i].stock_ingreso) * me.common.valDec(me.detalles[i].otros_item), 4);
            }
            return resultado;
        },
    },

    watch: {
        changeIdProduct(newId, oldId) {
            if (newId !== null && newId !== undefined && newId !== '' && !isNaN(newId)) {
                this.shearchIdProduct(newId);
            }
        }
    },

    created() {
        this.select();
        this.fechaSelectComprob(this.fecha_comprobante);
        this.fechaSeleccionada(this.fecha_cronograma);
        this.igv = parseFloat(this.datos.igv);
        this.tc = parseFloat(this.datos.tc);
    },

    methods: {
        ...mapActions(["snackBar"]),

        shearchIdProduct(id) {
            //this.snackBar({ cl: "success", msg: val });
            let me = this;

            axios.get("api/Productos/BuscarIdProducto/" + id)
                .then(function (response) {
                    //console.log(response);
                    me.agregarDetalle(response.data);
                    me.snackBar({ cl: "info", msg: "Se agrego " + response.data.descripcion + " al detalle." });
                    me.codigo_barra = "";
                })
                .catch(function (error) {
                    //console.log(error);
                    me.snackBar({ cl: "warning", msg: "No existe registro del producto." });
                    me.codigo_barra = "";
                });
        },

        // Convertimos DD/MM/AAAA a AAAA-MM-DD
        fechaConvertida(dia) {
            let arrayFecha = dia.split(["/"]);
            let yymmdd = arrayFecha[2] + "-" + arrayFecha[1] + "-" + arrayFecha[0];
            return yymmdd;
        },

        // Convertimos fecha AAAA-MM-DD a DD/MM/AAAA
        fechaSeleccionada(dia) {
            let arrayFecha = dia.split(["-"]);
            let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
            this.fecha_cronograma_formateada = ddmmyy;
        },

        fechaSelectComprob(dia) {
            let arrayFecha = dia.split(["-"]);
            let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
            this.fecha_comprobante_formateada = ddmmyy;
        },

        //#region SELECT
        selectProveedor(id) {
            let me = this;

            var proveedoresArray = [];

            if (id) {
                axios.get("api/Personas/SelectPersonas/" + id)
                    .then(function (response) {
                        proveedoresArray = response.data;
                        proveedoresArray.map(function (x) {
                            me.proveedores.push({
                                text: x.num_documento + " " + x.nombre,
                                value: x.idpersona,
                            });
                        });
                    })
                    .catch(function (error) {
                        //console.log(url);
                    });
            } else {
                axios.get("api/Personas/SelectPersonasFiltro", {
                    params: {
                        buscar: me.buscar_proveedor == "" ? "-" : me.buscar_proveedor == null ? "-" : me.buscar_proveedor,
                        tipo_doc: "-"
                    },
                })
                    .then(function (response) {
                        proveedoresArray = response.data;
                        proveedoresArray.map(function (x) {
                            me.proveedores.push({
                                text: x.num_documento + " - " + x.nombre,
                                value: x.idpersona,
                            });
                        });
                    })
                    .catch(function (error) {
                        //console.log(url);
                    });
            }
        },

        select() {
            let me = this;
            var tipo_serviciosArray = [];
            axios.get("api/Tipo_Servicios/SelectMonCompra")
                .then(function (response) {
                    tipo_serviciosArray = response.data;
                    tipo_serviciosArray.map(function (x) {
                        me.tipo_servicios.push({
                            text: x.nombre,
                            value: x.idtipo_servicio,
                        });
                    });
                })
                .catch(function (error) {
                    //console.log(error);
                });

            var tipo_comprobantesArray = [];
            axios.get("api/Tipo_Comprobantes/SelectCompra")
                .then(function (response) {
                    tipo_comprobantesArray = response.data;
                    tipo_comprobantesArray.map(function (x) {
                        me.tipo_comprobantes.push({
                            text: x.descripcion,
                            value: x.idtipo_comprobante + "-" + x.descripcion,
                        });
                    });
                })
                .catch(function (error) {
                    //console.log(error);
                });

            var tipo_documentosArray = [];
            axios.get("api/Tipo_Documentos/Select")
                .then(function (response) {
                    tipo_documentosArray = response.data;
                    tipo_documentosArray.map(function (x) {
                        me.tipo_documentos_cli.push({
                            text: x.descripcion,
                            value: x.idtipo_documento,
                        });
                    });
                })
                .catch(function (error) {
                    //console.log(error);
                });


            var tipo_pagosArray = [];
            axios.get("api/Tipo_Pagos/SelectCompra")
                .then(function (response) {
                    tipo_pagosArray = response.data;
                    tipo_pagosArray.map(function (x) {
                        me.tipo_pagos.push({
                            text: x.descripcion,
                            value: x.idtipo_pago,
                        });
                    });
                })
                .catch(function (error) {
                    //console.log(error);
                });
        },
        //#endregion SELECT

        //#region COMPRAS

        guardarCompra() {
            let me = this;
            me.detalle_compras = []; // Limpiamos array para llenarlo
            // Validamos si el detalle de pedido esta vacio
            if (me.detalles.length <= 0) {
                return me.snackBar({ cl: "warning", msg: "Ingrese al menos un producto al detalle." });
            }
            me.convertirDetCompra(); // Convertir detalles a entero y decimal
            me.desabilitar = true; // Desabilitamos boton guardar

            axios.post("api/Movimientos/CrearCompra", {
                act_pprod_comp: !me.datos.actpprodcompra ? "SI" : me.datos.actpprodcompra,
                idsucursal: parseInt(me.usuario.idsucursal),
                idpersona: me.idpersona,
                idusuario: parseInt(me.usuario.idusuario),
                idpedido: me.idpedido,
                idtipo_servicio: me.idtipo_servicio,
                idtipo_pago: me.idtipo_pago,
                idtipo_comprobante: me.idtipo_comprobante,
                serie_comprobante: me.serie_comprobante,
                num_comprobante: me.num_comprobante,
                fecha_comprobante: me.fecha_comprobante,
                igv: me.igv,
                descto_global: me.common.valDec(me.descto_global),
                descto_item_total: me.common.valDec(me.descto_item_total),
                otros_item_total: me.common.valDec(me.otros_item_total),
                seguro: me.common.valDec(me.seguro),
                inafecto: 0,
                exonerado: 0,
                subtotal_gravado: parseFloat(me.subtotal_gravado),
                gratuito: parseFloat(me.gratuito),
                igv_gravado: parseFloat(me.igv_gravado),
                importe_total: parseFloat(me.importe_total),

                detalle_compras: me.detalle_compras,
            })
                .then(function (response) {
                    me.ocultarNuevaCompra();
                    me.snackBar({ cl: "success", msg: response.data });
                    me.limpiar();
                })
                .catch(function (error) {
                    me.snackBar({ cl: "error", msg: error.response.data });
                    //console.log(error);
                });
        },

        convertirDetCompra() {
            let me = this;
            for (var i = 0; i < me.detalles.length; i++) {
                me.detalle_compras.push({
                    idproducto: me.detalles[i].idproducto,
                    serie: me.detalles[i].serie,
                    stock_ingreso: parseInt(me.detalles[i].stock_ingreso),
                    stock_actual: parseInt(me.detalles[i].stock_ingreso),
                    valor_unit_compra: parseFloat(me.detalles[i].valor_unit_compra),
                    precio_unit_compra: me.igv == 0 ? parseFloat(me.detalles[i].valor_unit_compra) : me.common.roundOut(parseFloat(me.detalles[i].valor_unit_compra) * me.igv + parseFloat(me.detalles[i].valor_unit_compra), 4),
                    descto_item: me.common.valDec(me.detalles[i].descto_item),
                    otros_item: me.common.valDec(me.detalles[i].otros_item),
                    idtipo_afectacion: me.detalles[i].idtipo_afectacion,
                });
            }
        },

        llenarCeroNumComprobante(num_comprobante) {
            this.num_comprobante = num_comprobante.replace(/\w\S*/g, (w) =>
                w.replace(w, (c) => ("00000000" + c).slice(-8))
            );
            //this.num_comprobante = ("00000000" + num_comprobante).slice(-8);
        },

        comprobanteSeleccionado(comprobantes) {
            let arrayComprobante = comprobantes.split(["-"]);
            this.idtipo_comprobante = arrayComprobante[0]; // idtipo_comprobante
            this.comprobante = arrayComprobante[1]; // nombre comprobante

            if (this.idtipo_comprobante == "00") {
                // Si es Recibo interno
                this.disabled = true;
                this.igv = 0;
                this.serie_comprobante = "0000";
                this.num_comprobante = "00000000";
            } else {
                // Si es factura, boleta, guia
                this.disabled = false;
                this.igv = parseFloat(this.datos.igv);
                this.serie_comprobante = this.comprobante.substr(0, 1);
                this.num_comprobante = "";
            }
        },

        ocultarNuevaCompra() {
            this.desabilitar = false; // Habilitamos boton guardar pedido y compra
            this.limpiar();
        },

        agregarDetalle(data = []) {
            let me = this;

            me.detalles.push({
                idproducto: data["idproducto"],
                serie: "",
                //codigo_producto: data["codigo_producto"],
                //categoria: data["categoria"],
                //marca: data["marca"],
                idunidad_medida: data["idunidad_medida"],
                descripcion: data["codigo_producto"] + " " + data["descripcion"] + " " + data["categoria"] + " " + data["marca"],
                stock_ingreso: 1,
                valor_unit_compra: me.common.roundOut((me.idtipo_servicio == "3" ? data["precio_unit_compras"] : me.idtipo_servicio == "4" ? data["precio_unit_comprad"] : 0) / (1 + me.igv), 4),
                descto_item: 0,
                otros_item: me.common.roundOut(me.idtipo_servicio == "3" ? data["otross"] : me.idtipo_servicio == "4" ? data["otrosd"] : 0, 4),
                idtipo_afectacion: data["idtipo_afectacion"],
            });
        },

        // Eliminar detalle pedido
        eliminarDetallePedido(arr, item) {
            var i = arr.indexOf(item);
            if (i !== -1) {
                arr.splice(i, 1);
            }
        },

        limpiar() {
            this.idmovimiento = "";
            this.moneda = "";
            this.idtipo_servicio = 4; // Compra ME
            this.idpersona = "";
            this.proveedor = "";
            this.num_documento = "";
            this.comprobante = "";
            this.serie_comprobante = "";
            this.num_comprobante = "";
            this.detalles = [];
            this.detalle_compras = [];
            this.idpedido = 0;

            this.descto_global = 0;
            this.descto_item_total = 0;
            this.seguro = 0;
            this.subtotal_gravado = 0;
            this.gratuito = 0;
            this.igv_gravado = 0;
            this.importe_total = 0;

            this.idtipo_pago = "";
            this.idtipo_comprobante = "";
            this.comprobante = "";
            this.comprobantes = "";
            this.serie_comprobante = "";
            this.num_comprobante = "";
            this.disabled = true;

            this.fecha_comprobante = moment().format("YYYY-MM-DD"); // X defecto
            this.fechaSelectComprob(this.fecha_comprobante);
        },
        //#endregion COMPRAS

    }
}
</script>
<style scoped>
.right-input>>>input {
    text-align: right;
}

.center-input>>>input {
    text-align: center;
}
</style>