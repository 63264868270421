<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout alig-start>
      <v-flex>
        <v-data-table :loading="loading" :headers="cabeceraMovimientoTipo" :items="movimientoTipo" :items-per-page="15"
          dense class="elevation-3">
          <template v-slot:top>
            <v-form v-model="isValid">
              <v-toolbar flat>
                <v-toolbar-title class="hidden-sm-and-down">TIPO MOVIMIENTO</v-toolbar-title>
                <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
                <!-- Formulario busqueda -->
                <v-btn icon small color="print" @click="crearPDF()" :disabled="desabilitar || !isValid" class="mr-1">
                  <v-icon>print</v-icon>
                </v-btn>
                <v-btn icon small color="success" @click="crearXLSX()" :disabled="desabilitar || !isValid" class="mr-1">
                  <v-icon>description</v-icon>
                </v-btn>
                <v-spacer />
                <v-autocomplete dense v-model="idsucursal" :items="selectSucursales"
                  :search-input.sync="buscar_sucursal" label="Establecimiento" :rules="[v => !!v || 'Requerido']"
                  @change="movimientoTipo = []" class="mr-1" hide-details></v-autocomplete>
                <v-autocomplete dense v-model="idtipo_movimiento" :items="selectMovimientos"
                  :search-input.sync="buscar_movimiento" label="Movimientos" :rules="[v => !!v || 'Requerido']"
                  @change="movimientoTipo = []" class="mr-1" hide-details></v-autocomplete>
                <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                  min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                  <template v-slot:activator="{ on }">
                    <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details class="mr-1" style="max-width: 190px;">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                    range>
                  </v-date-picker>
                </v-menu>
                <v-btn icon small color="primary" @click="listar" :loading="desabilitar"
                  :disabled="desabilitar || !isValid">
                  <v-icon>search</v-icon>
                </v-btn>
                <v-spacer />
                <!-- Fin Formulario Busqueda -->
              </v-toolbar>
            </v-form>
          </template>

          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>
          <template v-slot:item.importe_total="{ item }">
            <div class="text-right">
              <span>{{ item.importe_total | formatearNumero }}</span>
            </div>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import numeral from "numeral";
import XLSX from "xlsx";

export default {
  data() {
    return {
      movimientoTipo: [],
      dialog: false,
      cabeceraMovimientoTipo: [
        //{ text: "CdSc", value: "idsucursal" },
        { text: "CdUs", value: "idusuario", sortable: false },
        { text: "CdMv", value: "idmovimiento", sortable: false },
        //{ text: "CdSv", value: "idtipo_servicio" },
        { text: "Comprobante", value: "comprobante", sortable: false },
        { text: "Fecha", value: "fecha", sortable: false },
        { text: "Servicio", value: "servicio", sortable: false },
        { text: "Detalle", value: "glosa", sortable: false },
        { text: "Importe", value: "importe_total", sortable: false },
        { text: "Tipo Pago", value: "tipo_pago", sortable: false },
        { text: "Estado", value: "estado", sortable: false },
      ],

      idtipo_movimiento: "",
      selectMovimientos: [],
      idsucursal: "",
      selectSucursales: [],

      buscar_sucursal: "",
      buscar_movimiento: "",

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      desabilitar: false,
      isValid: true,
      loading: false,
    };
  },

  computed: {
    ...mapState(["usuario", "logo"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  created() {
    this.select();
    this.idsucursal = parseInt(this.usuario.idsucursal);
  },

  methods: {
    ...mapActions(["snackBar"]),

    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;

      axios.get("api/Movimientos/ReporteMovimientoxTipo", {
        params: {
          idsucursal: me.idsucursal,
          idtipo_movimiento: me.idtipo_movimiento,
          fechaInicio: me.dates[0],
          fechaFin:
            me.dates[1] == undefined
              ? me.dates[0] + " 23:59:59"
              : me.dates[1] + " 23:59:59",
        },
      })
        .then(function (response) {
          me.movimientoTipo = response.data;
          me.desabilitar = false;
          me.loading = false;
          me.snackBar({ cl: "success", msg: "Procesado correctamente." });
          //console.log(me.movimientoTipo);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.snackBar({ cl: "error", msg: "Ocurrio un error" });
          //console.log(err.response);
        });
    },

    select() {
      let tm = this;
      var tipo_movimientosArray = [];
      axios.get("api/Tipo_Movimientos/SelectAll")
        .then(function (response) {
          tipo_movimientosArray = response.data;
          tipo_movimientosArray.map(function (x) {
            tm.selectMovimientos.push({
              text: x.nombre,
              value: x.idtipo_movimiento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      let su = this;
      var sucursalesArray = [];
      axios.get("api/Sucursales/Select")
        .then(function (response) {
          sucursalesArray = response.data;
          sucursalesArray.map(function (x) {
            su.selectSucursales.push({ text: x.nombre, value: x.idsucursal });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    crearPDF() {
      var columns = [
        { header: "CdSc", dataKey: "idsucursal" },
        { header: "CdUs", dataKey: "idusuario" },
        { header: "CdMv", dataKey: "idmovimiento" },
        { header: "CdSv", dataKey: "idtipo_servicio" },
        { header: "Comprobante", dataKey: "comprobante" },
        { header: "Fecha", dataKey: "fecha" },
        { header: "Servicio", dataKey: "servicio" },
        { header: "Detalle", dataKey: "detalle" },
        { header: "Importe", dataKey: "importe_total" },
        { header: "Estado", dataKey: "estado" },
      ];
      var body = [];
      var foot = [];

      var totalimporte = 0.0;

      for (var i = 0; i < this.movimientoTipo.length; i++) {
        totalimporte =
          totalimporte + parseFloat(this.movimientoTipo[i].importe_total);
        body.push({
          idsucursal: this.movimientoTipo[i].idsucursal,
          idusuario: this.movimientoTipo[i].idusuario,
          idmovimiento: this.movimientoTipo[i].idmovimiento,
          idtipo_servicio: this.movimientoTipo[i].idtipo_servicio,
          comprobante: this.movimientoTipo[i].comprobante,
          fecha: moment(this.movimientoTipo[i].fecha).format("DD/MM/YYYY"),
          servicio: this.movimientoTipo[i].servicio,
          detalle: this.movimientoTipo[i].glosa,
          importe_total: numeral(this.movimientoTipo[i].importe_total).format(
            "###,##0.00"
          ),
          estado: this.movimientoTipo[i].estado,
        });

        if (i === this.movimientoTipo.length - 1) {
          foot.push([
            {
              content: "Total ",
              colSpan: 8,
            },
            numeral(totalimporte).format("###,##0.00"),
          ]);
        }
      }

      // Titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 70, 49.39);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.text(
            "DETALLE DE MOVIMIENTOS",
            doc.internal.pageSize.width / 2,
            45,
            {
              align: "center",
            }
          );
          doc.setFontSize(8);
          doc.text(
            "TIPO MOVIMIENTO: " + this.buscar_movimiento.substr(0, 69),
            40,
            72
          );
          doc.text("ESTABLECIMIENTO: " + this.buscar_sucursal.substr(0, 69), 40, 86);
          doc.text(
            "DESDE: " + moment(this.dates[0]).format("DD/MM/YYYY"),
            380,
            86
          );
          doc.text(
            "HASTA: " + moment(this.dates[1]).format("DD/MM/YYYY"),
            480,
            86
          );
        }
      };
      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            {
              align: "center",
            }
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        columns,
        body,
        foot,
        margin: { top: 94 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 7 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 7,
          halign: "right",
        },
        columnStyles: {
          idsucursal: { cellWidth: 30, halign: "right" },
          idusuario: { cellWidth: 30, halign: "right" },
          idmovimiento: { cellWidth: 30, halign: "right" },
          idtipo_servicio: { cellWidth: 30, halign: "right" },
          comprobante: { cellWidth: 70 },
          fecha: { cellWidth: 45, halign: "center" },
          servicio: { cellWidth: 65.0 },
          detalle: { cellWidth: 110.28 },
          importe_total: { cellWidth: 60, halign: "right" },
          estado: { cellWidth: 45 },
        },
      });
      // 515.28 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      doc.save("DetalleMovimientos.pdf");
    },

    crearXLSX() {
      // On Click Excel download button
      var rows = [];

      this.movimientoTipo.map(function (x) {
        rows.push({
          CdSc: x.idsucursal,
          CdUs: x.idusuario,
          CdMv: x.idmovimiento,
          CdSv: x.idtipo_servicio,
          Comprobante: x.comprobante,
          Fecha: moment(x.fecha).format("DD/MM/YYYY"),
          Servicio: x.servicio,
          Detalle: x.glosa,
          Importe: x.importe_total,
          "Tipo Pago": x.tipo_pago,
          Estado: x.estado,
        });
      });

      var Detalle = XLSX.utils.json_to_sheet(rows);

      var wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, Detalle, "Movimiento x Tipo");

      // export Excel file
      XLSX.writeFile(
        wb,
        this.buscar_movimiento +
        " " +
        moment(this.dates[0]).format("DD-MM-YYYY") +
        " al " +
        moment(this.dates[1]).format("DD-MM-YYYY") +
        ".xlsx"
      );
    },
  },
};
</script>

