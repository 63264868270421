import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#2980B9',
        secondary: '#EEEEEE',
        accent: '#795548',
        error: '#EF5C54',
        info: '#F29B34',
        success: '#499642',
        warning: '#FFC107',
        print: '#684644'
      },
      /* dark: {
        primary: '#2980B9',
        secondary: '#EEEEEE',
        accent: '#795548',
        error: '#EF5C54',
        info: '#F29B34',
        success: '#499642',
        warning: '#FFC107',
        print: '#684644'
      } */
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'md',
  },

  //theme: { dark: false },
});
