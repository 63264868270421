<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout alig-start>
      <v-flex>
        <v-data-table :loading="loading" :headers="cabeceraKardex" :items="kardexs" :search="buscar" :items-per-page="15"
          dense class="elevation-3">
          <template v-slot:top>
            <v-form v-model="isValid">
              <v-toolbar flat>
                <v-toolbar-title class="hidden-sm-and-down">KARDEX</v-toolbar-title>
                <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
                <!-- Formulario busqueda -->
                <v-btn icon small color="success" @click="crearXLSX()" :disabled="desabilitar || !isValid" class="mr-1">
                  <v-icon>description</v-icon>
                </v-btn>
                <v-spacer />
                <v-autocomplete dense v-model="idsucursal" :items="selectSucursales" :search-input.sync="buscar_sucursal"
                  label="Establecimiento" :rules="[v => !!v || 'Requerido']" @change="kardexs = []" class="mr-1"
                  hide-details></v-autocomplete>
                <v-text-field v-model.trim="buscar" dense label="Búsqueda" single-line hide-details class="mr-1"
                  :disabled="desabilitar || !isValid"></v-text-field>
                <v-btn icon small color="primary" @click="listar" :loading="desabilitar"
                  :disabled="desabilitar || !isValid">
                  <v-icon>search</v-icon>
                </v-btn>
                <v-spacer />
                <!-- Fin Formulario Busqueda -->
              </v-toolbar>
            </v-form>
          </template>
          <template v-slot:item.codigos="{ item }">
            <strong class="primary--text">{{ item.codigo_producto }} </strong> <br /> {{ item.codigo_producto2 }}
          </template>
          <template v-slot:item.descripcion="{ item }">
            {{ item.descripcion }} <br /> {{ item.serie }}
          </template>
          <template v-slot:item.marca="{ item }">
            <strong class="error--text">{{ item.marca }}</strong>
          </template>
          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>
          <template v-slot:item.entrada_valor="{ item }">
            <div class="text-right">
              <span>{{ common.roundOut(item.entrada_valor, 2) | formatearNumero }}</span>
            </div>
          </template>
          <template v-slot:item.salida_valor="{ item }">
            <div class="text-right">
              <span>{{ common.roundOut(item.salida_valor, 2) | formatearNumero }}</span>
            </div>
          </template>
          <template v-slot:item.saldo_valor="{ item }">
            <div class="text-right">
              <span>{{ common.roundOut(item.saldo_valor, 2) | formatearNumero }}</span>
            </div>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import XLSX from "xlsx";

export default {
  data() {
    return {
      kardexs: [],
      dialog: false,
      cabeceraKardex: [
        { text: "Fechas", value: "fecha", sortable: false },
        { text: "Movimientos", value: "movimiento", sortable: false },
        { text: "Comprobantes", value: "comprobante", align: "center", sortable: false },
        { text: "Personas", value: "persona", sortable: false },
        { text: "Códigos", value: "codigos", sortable: false },
        { text: "Nombre", value: "descripcion", sortable: false },
        { text: "Categoría", value: "categoria", sortable: false },
        { text: "Marca", value: "marca", sortable: false },
        //{ text: "Serie", value: "serie", sortable: false, divider: true },
        { text: "Entrada Cantidad", value: "entrada_cantidad", align: "center", sortable: false },
        { text: "Salida Cantidad", value: "salida_cantidad", align: "center", sortable: false },
        { text: "Saldo Cantidad", value: "saldo_cantidad", align: "center", sortable: false, divider: true },
        { text: "Entrada Valor", value: "entrada_valor", align: "center", sortable: false, },
        { text: "Salida Valor", value: "salida_valor", align: "center", sortable: false, },
        { text: "Saldo Valor", value: "saldo_valor", align: "center", sortable: false, },
      ],
      idsucursal: "",
      selectSucursales: [],
      buscar_sucursal: "",
      buscar: "",
      desabilitar: false,
      isValid: true,
      loading: false,
    };
  },

  computed: {
    ...mapState(["usuario", "logo"]),
  },

  created() {
    this.select();
    this.idsucursal = parseInt(this.usuario.idsucursal);
  },

  methods: {
    ...mapActions(["snackBar"]),

    /* Listar */
    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;

      axios.get("api/Movimientos/Kardex/" + parseInt(me.idsucursal))
        .then(function (response) {
          me.kardexs = response.data;
          me.desabilitar = false;
          me.loading = false;
          me.snackBar({ cl: "success", msg: "Procesado correctamente." });
          //console.log(me.kardexs);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.snackBar({ cl: "error", msg: "Ocurrio un error" });
          //console.log(err.response);
        });
    },

    /* Seleccionar tipos */
    select() {
      let su = this;
      var sucursalesArray = [];
      axios.get("api/Sucursales/Select")
        .then(function (response) {
          sucursalesArray = response.data;
          sucursalesArray.map(function (x) {
            su.selectSucursales.push({ text: x.nombre, value: x.idsucursal });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    crearXLSX() {
      var rows = [];

      for (var i = 0; i < this.kardexs.length; i++) {
        rows.push({
          CdMv: this.kardexs[i].idmovimiento,
          Fecha: moment(this.kardexs[i].fecha).format("DD/MM/YYYY"),
          Movimiento: this.kardexs[i].movimiento + " " + (this.kardexs[i].moneda == "D" ? "Dolares" : this.kardexs[i].moneda == "S" ? "Soles" : ""),
          Persona: this.kardexs[i].persona,
          Comprobante: this.kardexs[i].comprobante,
          "Código Producto": this.kardexs[i].codigo_producto,
          "Código Producto 2": this.kardexs[i].codigo_producto2,
          Nombre: this.kardexs[i].descripcion,
          Serie: this.kardexs[i].serie,
          Categoría: this.kardexs[i].categoria,
          Marca: this.kardexs[i].marca,
          "Entrada Cantidad": this.kardexs[i].entrada_cantidad,
          "Salida Cantidad": this.kardexs[i].salida_cantidad,
          "Saldo Cantidad": this.kardexs[i].saldo_cantidad,
          "Entrada Valor": this.common.roundOut(this.kardexs[i].entrada_valor, 2),
          "Salida Valor": this.common.roundOut(this.kardexs[i].salida_valor, 2),
          "Saldo Valor": this.common.roundOut(this.kardexs[i].saldo_valor, 2),
        });
      }
      var Detalle = XLSX.utils.json_to_sheet(rows);

      var wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, Detalle, "Kardex control");

      XLSX.writeFile(wb, "Kardex.xlsx");
    },
  },
};
</script>

