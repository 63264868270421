<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout align-start>
      <v-flex>
        <!-- Busqueda listado cuentas cobradas -->
        <v-data-table :loading="loading" :headers="cabeceraCuentasCobradas" :items="cuentasCobradas"
          :items-per-page="15" dense class="elevation-3" v-if="verMovimiento == 0 && verDetalleCuenta == 0">
          <template v-slot:top>
            <v-toolbar flat v-if="verMovimiento == 0 && verDetalleCuenta == 0">
              <v-toolbar-title class="hidden-sm-and-down">COBRADAS</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- Formulario busqueda de cuenta cobrada -->
              <v-text-field dense class="mr-1" v-model.trim="buscar_cuenta_cobrada" label="Búsqueda"
                @keyup="listarCuentaCobrada()" single-line hide-details></v-text-field>
              <v-spacer />
              <v-menu v-model="menuFechaBuscar" :close-on-content-click="!menuFechaBuscar" transition="scale-transition"
                offset-y min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details append-icon="update"
                    @click:append="listarCuentaCobrada()" class="mr-1" style="max-width: 220px;"></v-text-field>
                </template>
                <v-date-picker v-model="dates"
                  @input="dates.length == 2 ? menuFechaBuscar = false : menuFechaBuscar = true"
                  @change="listarCuentaCobrada()" range></v-date-picker>
              </v-menu>
              <v-spacer />
              <!-- Fin Formulario busqueda de cuenta cobrada -->

              <!-- Modal anular cuenta cobrada -->
              <v-dialog v-model="adModal" max-width="290">
                <v-card>
                  <v-card-title class="justify-center">¿Anular Pago?</v-card-title>
                  <v-card-text>
                    Estás a punto de
                    <span>Anular</span>
                    el pago Nº {{ adId }}
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn color="info" @click="anularCerrar" small outlined>Cancelar</v-btn>
                    <v-btn color="error" @click="anular" small outlined>Anular</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal anular cuenta cobrada -->

              <template>
                <v-btn color="primary" @click="listarDetalleCuenta()" small outlined>Por Cobrar</v-btn>
              </template>
            </v-toolbar>
          </template>
          <template v-slot:item.numero="{ item }">
            <span>{{ item.serie_comprobante + "-" + item.num_comprobante }}</span>
          </template>
          <template v-slot:item.cliente="{ item }">
            <span>{{ (item.cliente).substr(0, 20) }}</span>
          </template>
          <template v-slot:item.pago="{ item }">
            <div class="text-right">
              <span>{{ item.moneda == "S" ? "S/" : "$" }} {{ item.pago | formatearNumero }}</span>
            </div>
          </template>
          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>
          <template v-slot:item.estado="{ item }">
            <template v-if="item.estado != 'Anulado'">
              <span>{{ item.estado }}</span>
            </template>
            <template v-else>
              <span class="error--text">{{ item.estado }}</span>
            </template>
          </template>
          <template v-slot:item.opciones="{ item }">
            <v-btn small icon>
              <v-icon color="print" @click="mostrarComprobante(item)">print</v-icon>
            </v-btn>
            <template v-if="item.estado != 'Anulado'">
              <v-btn small icon>
                <v-icon color="error" @click="anularMostrar(item)">delete</v-icon>
              </v-btn>
            </template>
          </template>
        </v-data-table>
        <!--Fin Busqueda listado cuentas cobradas  -->

        <!-- Modal Imresion -->
        <v-dialog v-model="comprobanteModal" max-width="600px" scrollable>
          <v-card>
            <v-toolbar dark dense class="primary" flat>
              <v-toolbar-title>{{ comprobante }}</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="printExternal()">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn icon @click="savePdf()">
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn icon @click="ocultarComprobante()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text style="height: 400px;" class="px-0">
              <pdf :src="stringPdf"></pdf>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal Imresion -->

        <!-- Busqueda listado cuenta por cobrar -->
        <v-data-table :loading="loading" :headers="cabeceraDetalleCuenta" :items="detalleCuenta" :items-per-page="15"
          dense class="elevation-3" v-if="verDetalleCuenta == 1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>POR COBRAR</v-toolbar-title>
              <v-divider class="mx-2" inset vertical></v-divider>
              <v-btn fab x-small color="info" class="mr-1" @click="mostrarCalendario()">
                <v-icon>calendar_today</v-icon>
              </v-btn>
              <v-spacer />
              <!-- Formulario busqueda detalle cuenta -->
              <v-text-field dense class="mr-1 text-xs-center" v-model.trim="buscar_detalle_cuenta" label="Búsqueda"
                @keyup="listarDetalleCuenta()" single-line hide-details></v-text-field>
              <v-spacer />
              <!-- Fin Formulario busqueda detalle cuenta -->
              <template>
                <v-btn color="primary" @click="ocultarDetalleCuenta(), listarCuentaCobrada()" small outlined>
                  Cobradas</v-btn>
              </template>

              <!-- Modal crear cuenta cobrada -->
              <v-dialog v-model="cobrarCuentaModal" max-width="610px" persistent>
                <v-card>
                  <v-toolbar dark dense class="primary" flat>
                    <v-toolbar-title>Cobrar Cuenta</v-toolbar-title>
                    <v-spacer />
                    <span>Nº {{ numero }} VCTO:{{ fecha_vencimiento | formatearFecha }}</span>
                  </v-toolbar>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-row class="mt-2">
                        <v-col cols="12">
                          <v-chip>
                            <v-icon left>business</v-icon>
                            <strong>{{ cliente }}</strong>
                          </v-chip>
                        </v-col>
                        <v-col cols="12" sm="2">
                          <v-text-field dense v-model.trim="importe" label="Importe"
                            :rules="[v => (!!v && v > 0) || 'Requerido', v => parseFloat(v) >= importev || 'Deben ser iguales']"
                            maxlength="9" @keypress="common.numDec($event, importe, 2)" onClick="this.select()"
                            hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="7">
                          <v-text-field dense v-model.trim="descripcion" label="Descripción"
                            :rules="[v => (v.length >= 3 && v.length <= 100) || 'La descripción debe tener de 3 a 100 caracteres', Rules.unespacio]"
                            maxlength="100" prepend-inner-icon="notes" hide-details v-uppercase></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-menu v-model="menuFecha" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field dense v-model="fecha_formateada" readonly v-on="on"
                                prepend-inner-icon="event" hide-details></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_comprobante" @input="menuFecha = false"
                              @change="fechaSeleccionada(fecha_comprobante)"></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 px-4 justify-center">
                    <v-btn color="error" @click="close()" small outlined>
                      <v-icon left>close</v-icon>Cancelar
                    </v-btn>
                    <v-btn color="primary" @click="guardar()" :loading="desabilitar" :disabled="desabilitar || !isValid"
                      small outlined>
                      <v-icon left>check</v-icon>Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal crear cuenta cobrada -->

              <!-- Modal ver calendario -->
              <v-dialog v-model="calendarioModal" max-width="750px">
                <v-card>
                  <v-col>
                    <v-sheet height="64">
                      <v-toolbar flat>
                        <v-btn class="mr-4" color="primary" @click="setToday">Hoy</v-btn>
                        <v-btn fab text small color="primary" @click="prev">
                          <v-icon small>keyboard_arrow_left</v-icon>
                        </v-btn>
                        <v-btn fab text small color="primary" class="mr-4" @click="next">
                          <v-icon small>keyboard_arrow_right</v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">{{ $refs.calendar.title }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-menu bottom right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" v-bind="attrs" v-on="on">
                              <span>{{ typeToLabel[type] }}</span>
                              <v-icon right>arrow_drop_down</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="type = 'day'">
                              <v-list-item-title>Dia</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                              <v-list-item-title>Semana</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                              <v-list-item-title>Mes</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = '4day'">
                              <v-list-item-title>4 Dias</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-toolbar>
                    </v-sheet>
                    <v-sheet height="600">
                      <v-calendar ref="calendar" v-model="focus" color="primary" :events="eventos_calendario"
                        :event-color="getEventColor" :type="type" :short-months="shortMonths"
                        :short-weekdays="shortWeekdays" @click:event="showEvent" @click:more="viewDay"
                        @click:date="viewDay"></v-calendar>
                      <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement"
                        offset-x>
                        <v-card color="grey lighten-4" min-width="350px" flat>
                          <v-toolbar :color="selectedEvent.color" dark>
                            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon>
                              <v-icon @click="selectedOpen = false">close</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-card-text>
                            Cuenta por cobrar: S/
                            <span v-html="selectedEvent.details"></span>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </v-sheet>
                  </v-col>
                </v-card>
              </v-dialog>
              <!-- Fin Modal ver calendario -->
            </v-toolbar>
          </template>
          <!-- Opciones -->
          <template v-slot:item.cliente="{ item }">
            <span>{{ (item.cliente).substr(0, 20) }}</span>
          </template>
          <template v-slot:item.fecha_vencimiento="{ item }">
            <template>
              <v-chip
                :color="(item.fecha_vencimiento.substr(0, 10) < fecha_actual ? 'error' : item.fecha_vencimiento.substr(0, 10) == fecha_actual ? 'warning' : 'success')">
                {{ item.fecha_vencimiento | formatearFecha }}</v-chip>
            </template>
          </template>
          <template v-slot:item.importe="{ item }">
            <div class="text-right">
              <strong>
                <span>{{ item.moneda == "S" ? "S/" : "$" }}{{ item.importe | formatearNumero }}</span>
              </strong>
            </div>
          </template>
          <template v-slot:item.opciones="{ item }">
            <v-btn small icon>
              <v-icon color="primary" @click="mostrarDetalleCuenta(item)">payments</v-icon>
            </v-btn>
          </template>
          <!-- Fin Opciones -->
        </v-data-table>
        <!--Fin Busqueda listado cuenta por cobrar -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import jsPDF from "jspdf";
import pdf from "vue-pdf";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      cuentasCobradas: [],
      detalleCuenta: [],
      cobrarCuentaModal: false,
      calendarioModal: false,

      cabeceraCuentasCobradas: [
        //{ text: "CdMv", value: "idmovimiento" },
        //{ text: "USUARIO", value: "usuario" },
        { text: "CLIENTE", value: "cliente", sortable: false },
        { text: "MOVIMIENTO", value: "movimiento", sortable: false },
        { text: "NÚMERO", value: "numero", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "IMPORTE", value: "pago", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false, width: 90 },
      ],
      cabeceraDetalleCuenta: [
        { text: "CLIENTE", value: "cliente", sortable: false },
        { text: "NÚMERO. REF.", align: "center", value: "numero_comp_ref", sortable: false },
        { text: "CdPd", align: "center", value: "idpedido", sortable: false },
        { text: "CxC", align: "center", value: "numero", sortable: false },
        { text: "FECHA VCTO", align: "center", value: "fecha_vencimiento", sortable: false, },
        { text: "IMPORTE", align: "center", value: "importe", sortable: false },
        { text: "ESTADO", align: "center", value: "estado", sortable: false },
        { text: "OPC.", value: "opciones", sortable: false, width: 50 },
      ],
      loading: false,
      
      buscar_cuenta_cobrada: "",
      buscar_detalle_cuenta: "",

      id: "",
      cliente: "",

      idpedido: "",

      // Movimientos
      // Detalle cuenta guardar
      comprobante: "",
      serie_comprobante: "",
      num_comprobante: "",
      ruc_comprobante: "",
      denom_comprobante: "",
      //Fin Detalle cuenta guardar
      fecha: "",
      movimiento: "",
      descripcion_caja: "",
      moneda: "",
      pago: "",

      // Detalle cuenta
      numero: "",
      fecha_vencimiento: "",
      importe: 0,
      importev: 0,

      idtipo_servicio: "",
      descripcion: "",
      fecha_actual: moment().format("YYYY-MM-DD"),

      fecha_comprobante: moment().format("YYYY-MM-DD"),

      menuFecha: false,
      desabilitar: false,
      isValid: true,
      disabled: true, // Serie y numero comprobante desabilitados

      verMovimiento: 0, // Mostrar ventana movimiento
      verDetalleCuenta: 0, // Mostrar ventana detalle cuenta

      comprobanteModal: 0,
      adModal: 0,
      adId: "",
      adFecha: "",

      /* Validaciones */
      Rules: {
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },

      // Calendario
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Mes",
        week: "Semana",
        day: "Dia",
        "4day": "4 Dias",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      eventos_calendario: [],
      shortMonths: false,
      shortWeekdays: false,
      name: "",
      details: "",
      colors: [
        "pink lighten-2",
        "blue",
        "indigo",
        "deep-purple",
        "teal",
        "cyan",
        "green",
        "light-blue",
        "orange",
        "grey",
        "brown",
        "deep-purple accent-2",
        "light-green",
      ],
      // Fin Calendario

      stringPdf: "",
      blobPdf: "",

      dates: [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ],
      menuFechaBuscar: false,
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  watch: {
    cobrarCuentaModal(val) {
      val || this.close();
    },
    calendarioModal(val) {
      val || this.close();
    },
  },

  created() {
    this.fechaSeleccionada(this.fecha_comprobante);
    this.listarCuentaCobrada();
  },

  methods: {
    ...mapActions(["cargarNotificaciones", "snackBar"]),

    fechaSeleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_formateada = ddmmyy;
    },

    //#region Calendario
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    listarEventos() {
      let me = this;
      var detalleCxCArray = [];
      const eventos_calendario = [];

      axios.get("api/Detalle_Cuentas/ListarDetalleCxC/" + parseInt(me.usuario.idsucursal))
        .then(function (response) {
          detalleCxCArray = response.data;

          detalleCxCArray.map(function (x) {
            eventos_calendario.push({
              name: x.cliente.substr(0, 25),
              details: numeral(x.importe).format("###,##0.00"),
              start: x.fecha_vencimiento.substr(0, 10) + " " + "10:00:00",
              end: x.fecha_vencimiento.substr(0, 10) + " " + "12:00:00",

              color:
                x.fecha_vencimiento.substr(0, 10) < me.fecha_actual
                  ? "error"
                  : x.fecha_vencimiento.substr(0, 10) == me.fecha_actual
                    ? "warning"
                    : me.colors[
                    Math.floor(
                      (me.colors.length - 1 - 0 + 1) * Math.random()
                    ) + 0
                    ], // Generamos un colors aleatorio
            });
          });
          me.eventos_calendario = eventos_calendario;
          // console.log(me.eventos_calendario);
        })
        .catch((err) => {
          //console.log(err.response);
        });
    },
    //#endregion Calendario

    // Listar cuentas cobradas
    listarCuentaCobrada() {
      let me = this;
      me.loading = true;

      let deNotificacion = me.$route.params.cuentaxcobrar; // Recibimos parametro cuentaxcobrar=true de App notificaciones
      if (deNotificacion === true) {
        me.listarDetalleCuenta();
        me.verDetalleCuenta = 1;
        me.$route.params.cuentaxcobrar = false;
      }

      axios.get("api/Movimientos/ListarCuentaCobradaFiltro", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
          buscar:
            me.buscar_cuenta_cobrada == "" ? "-" : me.buscar_cuenta_cobrada,
          fechaInicio: me.dates[0],
          fechaFin:
            me.dates[1] == undefined
              ? me.dates[0] + " 23:59:59"
              : me.dates[1] + " 23:59:59",
        },
      })
        .then(function (response) {
          me.cuentasCobradas = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    //#region Mostrar impresion
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName =
        "CuentaCobrada-" + this.serie_comprobante + "-" + this.num_comprobante;
      link.download = fileName;
      link.click();
    },

    printExternal() {
      printJS({
        printable: this.stringPdf.replace(
          "data:application/pdf;filename=generated.pdf;base64,",
          ""
        ),
        type: "pdf",
        base64: true,
      });
    },

    mostrarComprobante(item) {
      this.limpiar();
      this.id = item.idmovimiento;
      this.comprobante = item.comprobante;
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;

      this.ruc_comprobante = item.ruc_comprobante;
      this.denom_comprobante = item.denom_comprobante;
      this.fecha = item.fecha;
      this.moneda = item.moneda;
      this.pago = item.pago;
      this.movimiento = item.movimiento;
      this.descripcion_caja = item.descripcion_caja;
      this.idpedido = item.idpedido; // Guardar

      this.cliente = item.cliente;
      this.importe = item.importe;
      this.crearPDF();
      this.comprobanteModal = 1;
    },
    //#endregion Mostrar impresion

    // Modal Anular, cancelar cuenta cobrada
    anular() {
      let me = this;

      axios.put("api/Movimientos/AnularCuentaCobrada/" + me.adId + "/" + me.adFecha + "/" + me.usuario.idsucursal + "/" + me.usuario.idusuario)
        .then(function (response) {
          me.snackBar({ cl: "success", msg: response.data });
          me.adModal = 0;
          me.adId = "";
          me.adFecha = "";
          me.listarCuentaCobrada();
          me.cargarNotificaciones(); // Llamamos a actions para actualizar los notificaciones
        })
        .catch(function (error) {
          //console.log(error);
          me.snackBar({ cl: "error", msg: error.response.data });
          me.adModal = 0;
          me.adId = "";
          me.adFecha = "";
        });
    },

    anularMostrar(item) {
      this.adModal = 1;
      this.adId = item.idmovimiento;
      this.adFecha = item.fecha;
    },

    anularCerrar() {
      this.adModal = 0;
    }, // Fin Anular, cancelar cuenta cobrada

    // Ocultar modal impresion comprobante
    ocultarComprobante() {
      this.comprobanteModal = 0;
      this.limpiar();
    },
    /* Fin Mostrar comprobante para impresion */

    /* Detalle cuenta */
    // Listar detalle cuenta
    listarDetalleCuenta() {
      let me = this;
      me.loading = true;

      axios.get("api/Detalle_Cuentas/ListarDetalleCxCFiltro", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          buscar:
            me.buscar_detalle_cuenta == "" ? "-" : me.buscar_detalle_cuenta,
        },
      })
        .then(function (response) {
          me.detalleCuenta = response.data;
          me.loading = false;
        })
        .catch((err) => {
          //console.log(err.response);
        });
      this.verDetalleCuenta = 1;
    },

    // Cerrar modal (cuenta cobrada) detalle cuenta
    close() {
      this.cobrarCuentaModal = false;
      this.calendarioModal = false;
      this.desabilitar = false; // Habilitamos boton guardar
      this.limpiar();
    },

    // Ocultar ventana detalle cuenta
    ocultarDetalleCuenta() {
      this.verDetalleCuenta = 0;
    },

    // Mostramos calendario
    mostrarCalendario() {
      this.listarEventos();
      this.calendarioModal = true;
    },

    // Editar modal (cuenta cobrada) detalle cuenta item
    mostrarDetalleCuenta(item) {
      this.id = item.iddetalle_cuenta;
      this.cliente = item.cliente;
      this.numero = item.numero;
      this.fecha_vencimiento = item.fecha_vencimiento;
      this.importe = item.importe;
      this.importev = parseFloat(item.importe); // Para validar el importe no sea >= a importev
      this.idtipo_servicio = item.idtipo_servicio;
      this.idpedido = item.idpedido;
      this.cobrarCuentaModal = true;
    },

    // Guardar cuenta cobrada
    guardar() {
      let me = this;
      me.desabilitar = true;

      axios.post("api/Movimientos/CrearCuentaCobrada", {
        idsucursal: parseInt(me.usuario.idsucursal),
        idusuario: parseInt(me.usuario.idusuario),
        idpedido: me.idpedido,
        idtipo_servicio: me.idtipo_servicio, // Si la venta fue en soles tipo_servicio=5, cambiamos el tipo_servicio=9
        importe_total: parseFloat(me.importe),
        // detalle_credito
        iddetalle_cuenta: me.id,
        //Detalle Caja
        descripcion: me.descripcion,
        fecha_comprobante: me.fecha_comprobante,
        idpersona: 1,
      })
        .then(function (response) {
          me.snackBar({ cl: "success", msg: response.data });
          me.close();
          me.limpiar();
          me.listarCuentaCobrada();
          me.verDetalleCuenta = 0;
          me.cargarNotificaciones();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
          me.listarCuentaCobrada();
        });
    },

    limpiar() {
      this.id = ""; // idmovimiento - Cuentas cobradas
      this.pago = 0; // De movimientos
      this.movimiento = ""; // cuenta cobrada cliente - Cuentas cobradas
      this.descripcion_caja = "";
      this.comprobante = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";
      this.fecha = "";
      this.moneda = "";

      this.idtipo_servicio = "";

      // Detalle Caja
      this.descripcion = "";

      this.fecha_comprobante = moment().format("YYYY-MM-DD");
      this.fechaSeleccionada(this.fecha_comprobante);

      this.ruc_comprobante = "";
      this.denom_comprobante = "";

      this.stringPdf = "";
      this.blobPdf = "";

      //this.eventos_calendario = []; // Array cargados al calendario
    },

    crearPDF() {
      var columns = [
        {
          header: "DESCRIPCIÓN",
          dataKey: "descripcion",
        },
        {
          header: "IMPORTE",
          dataKey: "pago",
        },
      ];
      var body = [
        {
          descripcion: this.movimiento + " - " + this.descripcion_caja,
          pago: numeral(this.pago).format("###,##0.00"),
        },
      ];
      var foot = [
        {
          descripcion: "Total " + (this.moneda == "S" ? "S/" : "$"),
          pago: numeral(this.pago).format("###,##0.00"),
        },
      ];

      // Titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 70, 49.39);
          // Datos empresa
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(this.datos.empresa, 140, 28);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(8);
          doc.text(this.datos.dirsucursal, 140, 38);
          doc.text("Telf.: " + this.datos.telsucursal, 140, 48);
          doc.text("E-Mail: " + this.datos.mailsucursal, 140, 58);
          // Fin Datos empresa

          // Datos cliente
          doc.text("Razón Social: " + this.denom_comprobante, 40, 75);
          doc.text("Ruc N°: " + this.ruc_comprobante, 40, 90);
          doc.text("Establecimiento: " + this.datos.sucursal, 420, 75);
          doc.text(
            "Fecha: " + moment(this.fecha).format("DD/MM/YYYY"),
            420,
            90
          );

          // Fin Datos cliente

          // Datos documento
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(
            this.comprobante,
            doc.internal.pageSize.width / 2 + 140,
            42,
            "center"
          );
          doc.text(
            this.serie_comprobante + "-" + this.num_comprobante,
            doc.internal.pageSize.width / 2 + 140,
            54,
            "center"
          );
          // Fin Datos documento
        }
      };
      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            "center"
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        //theme: "grid",
        //tableLineWidth: 1,
        columns,
        body,
        foot,
        margin: { top: 100 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          descripcion: { cellWidth: "auto" },
          pago: { cellWidth: 50, halign: "right" },
        },
      });

      addHeaders(doc);
      addFooters(doc);
      // doc.save(
      //   "Cuentacobrada" +
      //     this.comprobante.substr(0, 1) +
      //     (this.comprobante == "Recibo Interno"
      //       ? this.id
      //       : this.serie_comprobante + "-" + this.num_comprobante) +
      //     ".pdf"
      // );
      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
    },
  },
};
</script>