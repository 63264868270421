<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout alig-start>
      <v-flex>
        <!-- Listado de cambio precios  -->
        <v-data-table :loading="loading" :headers="headers" :items="detCambios" :items-per-page="15" class="elevation-3"
          :search="buscar" dense>
          <!-- Llenamos datos en data table -->
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">CAMBIO DE PRECIOS</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <!-- Formulario busqueda de producto -->
              <v-spacer />
              <v-text-field class="text-xs-center mr-1" v-model.trim="buscar" dense label="Búsqueda" single-line
                @keyup="listar" hide-details></v-text-field>
              <v-spacer />
              <v-menu v-model="menuFechaBuscar" :close-on-content-click="!menuFechaBuscar" transition="scale-transition"
                offset-y min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details append-icon="update"
                    @click:append="listar()" class="mr-1" style="max-width: 220px;"></v-text-field>
                </template>
                <v-date-picker v-model="dates"
                  @input="dates.length == 2 ? menuFechaBuscar = false : menuFechaBuscar = true" @change="listar()"
                  range></v-date-picker>
              </v-menu>
              <!-- Fin Formulario busqueda de producto -->

              <v-spacer />
              <!-- Modal crear y mofificar precios producto -->
              <v-dialog v-model="dialog" max-width="1300px" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" class="hidden-sm-and-down" small v-on="on" outlined>Nuevo</v-btn>
                  <v-btn color="primary" class="hidden-md-and-up" v-on="on" fab x-small outlined>
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <v-form v-model="isValid">
                  <v-data-table :loading="loadingPrecios" :headers="moneda == 'D' ? headersPreciosD : headersPreciosS"
                    :items="detPreciosCarga" item-key="idproducto" show-expand :search="buscarProducto" dense
                    class="elevation-3">
                    <template v-slot:top>
                      <v-toolbar dark dense class="primary mb-1" flat>
                        <v-toolbar-title class="mr-2 hidden-sm-and-down">{{ formTitle }}</v-toolbar-title>
                        <v-spacer />
                        <!-- Formulario busqueda de productos -->
                        <v-select dense :items="monedas" v-model="moneda" label="Moneda" single-line
                          :rules="[v => !!v || 'Requerido']" @change="listarPrecios" required hide-details
                          :disabled="editedIndex == 1 ? true : false" class="mr-1"></v-select>
                        <v-spacer />
                        <v-text-field v-model.trim="buscarProducto" append-icon="search" dense label="Búsqueda"
                          single-line hide-details clearable class="mr-1"></v-text-field>
                        <v-spacer />
                        <template v-if="editedIndex != 1" class="mr-1">TC {{ tc }}</template>
                        <!-- Fin Formulario Busqueda de productos -->
                        <v-spacer />
                        <v-btn icon @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                          v-if="editedIndex != 1">
                          <v-icon>save</v-icon>
                        </v-btn>
                        <v-btn icon @click="close">
                          <v-icon>close</v-icon>
                        </v-btn>
                        <!-- Fin Modal activar, desactivar categoria -->
                      </v-toolbar>
                    </template>
                    <!-- Opciones de precios -->
                    <template v-slot:item.num="{ item }">
                      <td>{{ detPreciosCarga.indexOf(item) + 1 }}</td>
                    </template>
                    <!-- ANTERIOR -->
                    <!-- Precio compra anterior -->
                    <template v-slot:item.precio_unit_comprad_ant="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_comprad_ant"
                        :rules="[v => !!v || 'Requerido']" maxlength="9" hide-details disabled
                        @keypress="common.numDec($event, item.precio_unit_comprad_ant, 4)" onClick="this.select()">
                      </v-text-field>
                    </template>
                    <template v-slot:item.precio_unit_compras_ant="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_compras_ant"
                        :rules="[v => !!v || 'Requerido']" maxlength="10" hide-details disabled
                        @keypress="common.numDec($event, item.precio_unit_compras_ant, 4)" onClick="this.select()">
                      </v-text-field>
                    </template>
                    <!-- Fin Precio compra anterior -->
                    <template v-slot:item.precio_unit_venta_ant="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_venta_ant"
                        :rules="[v => !!v || 'Requerido']" maxlength="9" hide-details disabled
                        @keypress="common.numDec($event, item.precio_unit_venta_ant, 4)" onClick="this.select()">
                      </v-text-field>
                    </template>
                    <template v-slot:item.precio_unit_cliente_ant="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_cliente_ant"
                        :rules="[v => !!v || 'Requerido']" maxlength="9" hide-details disabled
                        @keypress="common.numDec($event, item.precio_unit_cliente_ant, 4)" onClick="this.select()">
                      </v-text-field>
                    </template>
                    <template v-slot:item.precio_unit_distribuidor_ant="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_distribuidor_ant"
                        :rules="[v => !!v || 'Requerido']" maxlength="9" hide-details disabled
                        @keypress="common.numDec($event, item.precio_unit_distribuidor_ant, 4)" onClick="this.select()">
                      </v-text-field>
                    </template>
                    <!-- FIN ANTERIOR -->
                    <!-- NUEVO -->
                    <!-- Precio compra nuevo -->
                    <template v-slot:item.precio_unit_comprad="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_comprad"
                        :rules="[v => !!v && v > 0 || 'Requerido']"
                        @keyup.prevent="item.precio_unit_compras = common.roundOut((common.valDec(item.precio_unit_comprad) * tc), 4).toFixed(4)"
                        @keyup.native="item.precio_unit_venta = common.roundOut((common.valDec(item.precio_unit_compras) + common.valDec(item.otross) + ((common.valDec(item.precio_unit_compras) + common.valDec(item.otross)) * common.valDec(item.utilidad_unit_venta)) / 100), 0),
                          item.precio_unit_cliente = common.roundOut((common.valDec(item.precio_unit_compras) + common.valDec(item.otross) + ((common.valDec(item.precio_unit_compras) + common.valDec(item.otross)) * common.valDec(item.utilidad_unit_cliente)) / 100), 0),
                          item.precio_unit_distribuidor = common.roundOut((common.valDec(item.precio_unit_compras) + common.valDec(item.otross) + ((common.valDec(item.precio_unit_compras) + common.valDec(item.otross)) * common.valDec(item.utilidad_unit_distribuidor)) / 100), 0)"
                        maxlength="10" hide-details :disabled="editedIndex == 1 ? true : false"
                        @keypress="common.numDec($event, item.precio_unit_comprad, 4)" onClick="this.select()">
                      </v-text-field>
                    </template>
                    <template v-slot:item.precio_unit_compras="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_compras"
                        :rules="[v => !!v && v > 0 || 'Requerido']"
                        @keyup.prevent="item.precio_unit_comprad = common.roundOut((common.valDec(item.precio_unit_compras) / tc), 4).toFixed(4)"
                        @keyup.native="item.precio_unit_venta = common.roundOut((common.valDec(item.precio_unit_compras) + common.valDec(item.otross) + ((common.valDec(item.precio_unit_compras) + common.valDec(item.otross)) * common.valDec(item.utilidad_unit_venta)) / 100), 0),
                          item.precio_unit_cliente = common.roundOut((common.valDec(item.precio_unit_compras) + common.valDec(item.otross) + ((common.valDec(item.precio_unit_compras) + common.valDec(item.otross)) * common.valDec(item.utilidad_unit_cliente)) / 100), 0),
                          item.precio_unit_distribuidor = common.roundOut((common.valDec(item.precio_unit_compras) + common.valDec(item.otross) + ((common.valDec(item.precio_unit_compras) + common.valDec(item.otross)) * common.valDec(item.utilidad_unit_distribuidor)) / 100), 0)"
                        maxlength="10" hide-details :disabled="editedIndex == 1 ? true : false"
                        @keypress="common.numDec($event, item.precio_unit_compras, 4)" onClick="this.select()">
                      </v-text-field>
                    </template>
                    <!-- Fin Precio compra nuevo -->
                    <template v-slot:item.precio_unit_venta="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_venta"
                        :rules="[v => !!v && v > 0 || 'Requerido']" maxlength="9" hide-details
                        :disabled="editedIndex == 1 ? true : false"
                        @keypress="common.numDec($event, item.precio_unit_venta, 4)"
                        onClick="this.select()"></v-text-field>
                    </template>
                    <template v-slot:item.precio_unit_cliente="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_cliente"
                        :rules="[v => !!v && v > 0 || 'Requerido']" maxlength="9" hide-details
                        :disabled="editedIndex == 1 ? true : false"
                        @keypress="common.numDec($event, item.precio_unit_cliente, 4)"
                        onClick="this.select()"></v-text-field>
                    </template>
                    <template v-slot:item.precio_unit_distribuidor="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_distribuidor"
                        :rules="[v => !!v && v > 0 || 'Requerido']" maxlength="9" hide-details
                        :disabled="editedIndex == 1 ? true : false"
                        @keypress="common.numDec($event, item.precio_unit_distribuidor, 4)"
                        onClick="this.select()"></v-text-field>
                    </template>
                    <!-- FIN NUEVO -->
                    <template v-slot:item.opciones="{ item }">
                      <v-icon small color="error" class="mr-2" :disabled="editedIndex == 1 ? true : false"
                        @click="eliminarItemDetallePrecio(detPreciosCarga, item)">delete_forever</v-icon>
                    </template>
                    <!-- Mas detalles-->
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <strong>Fecha Modificación Producto:</strong>
                        {{ item.fecha_modificacion | formatearFechaHm }}
                        <!-- <strong>Precio Compra S/ :</strong>
                        {{item.precio_unit_compras}}-->
                        <br />
                        <strong>Ganancia Venta Ant.:</strong>
                        {{ item.utilidad_unit_venta_ant.toFixed(4) }}%
                        <strong class="ml-3">Ganancia Cliente Ant.:</strong>
                        {{ item.utilidad_unit_cliente_ant.toFixed(4) }}%
                        <strong class="ml-3">Ganancia Distribuidor Ant.:</strong>
                        {{ item.utilidad_unit_distribuidor_ant.toFixed(4) }}%
                        <br />
                        <strong>Otros Anterior $:</strong>
                        {{ item.otrosd_ant }}
                        <strong class="ml-3">Otros Anterior S/:</strong>
                        {{ item.otross_ant }}
                      </td>
                    </template>
                    <!-- Fin Opciones de precios -->
                  </v-data-table>
                </v-form>
              </v-dialog>
              <!-- Fin Modal crear y mofificar precios producto -->

              <!-- Modal anular cambio precio -->
              <v-dialog v-model="adModal" max-width="310">
                <v-card>
                  <v-card-title class="justify-center">¿Anular Cambio precio?</v-card-title>
                  <v-card-text>
                    Estás a punto de
                    <span>anular</span>
                    el cambio precio Nº {{ adId }} con fecha {{ adFecha | formatearFechaHm }}
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn color="info" @click="anularCerrar" small outlined>Cancelar</v-btn>
                    <v-btn color="error" @click="anular" small outlined>Anular</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal anular cambio precio -->
            </v-toolbar>
          </template>

          <!-- Opciones editar, eliminar -->

          <template v-slot:item.fecha="{ item }">{{ item.fecha | formatearFechaHm }}</template>
          <template v-slot:item.opciones="{ item }">
            <v-btn small icon>
              <v-icon color="primary" @click="editItem(item)">visibility</v-icon>
            </v-btn>
            <template v-if="item.estado != 'Anulado'">
              <v-btn small icon>
                <v-icon color="error" @click="anularMostrar(item)">delete</v-icon>
              </v-btn>
            </template>
          </template>
          <!-- Fin Opciones editar, eliminar -->
        </v-data-table>
        <!-- Fin Listado de cambio precios-->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      detCambios: [],
      detPreciosCarga: [],
      detPreciosDecimal: [],
      dialog: false,

      headers: [
        { text: "CdCp", value: "idcambio_precio", sortable: false },
        { text: "USUARIO", value: "usuario", sortable: false },
        { text: "MONEDA", value: "nommoneda", sortable: false },
        { text: "T/C", value: "cambio", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "ESTADO", align: "center", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false, width: 90 },
      ],

      headersPreciosD: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "#", value: "num", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        // ANTERIORES
        { text: "Precio Compra Anterior $", align: "center", value: "precio_unit_comprad_ant", sortable: false },
        { text: "Precio Venta Anterior S/", align: "center", value: "precio_unit_venta_ant", sortable: false },
        { text: "Precio Cliente Anterior S/", align: "center", value: "precio_unit_cliente_ant", sortable: false },
        { text: "Precio Distrib. Anterior S/", align: "center", value: "precio_unit_distribuidor_ant", sortable: false },
        // NUEVOS
        { text: "NUEVO PRECIO COMPRA $", align: "center", value: "precio_unit_comprad", sortable: false },
        { text: "NUEVO PRECIO VENTA S/", align: "center", value: "precio_unit_venta", sortable: false },
        { text: "NUEVO PRECIO CLIENTE S/", align: "center", value: "precio_unit_cliente", sortable: false },
        { text: "NUEVO PRECIO DISTRIB. S/", align: "center", value: "precio_unit_distribuidor", sortable: false },
        { text: "OPC.", value: "opciones", sortable: false },
      ],

      headersPreciosS: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "#", value: "num", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        // ANTERIORES
        { text: "Precio Compra Anterior S/", align: "center", value: "precio_unit_compras_ant", sortable: false },
        { text: "Precio Venta Anterior S/", align: "center", value: "precio_unit_venta_ant", sortable: false },
        { text: "Precio Cliente Anterior S/", align: "center", value: "precio_unit_cliente_ant", sortable: false },
        { text: "Precio Distrib. Anterior S/", align: "center", value: "precio_unit_distribuidor_ant", sortable: false },
        // NUEVOS
        { text: "NUEVO PRECIO COMPRA S/", align: "center", value: "precio_unit_compras", sortable: false },
        { text: "NUEVO PRECIO VENTA S/", align: "center", value: "precio_unit_venta", sortable: false },
        { text: "NUEVO PRECIO CLIENTE S/", align: "center", value: "precio_unit_cliente", sortable: false },
        { text: "NUEVO PRECIO DISTRIB. S/", align: "center", value: "precio_unit_distribuidor", sortable: false },
        { text: "OPC.", value: "opciones", sortable: false },
      ],
      loading: false,

      buscar: "",
      buscarProducto: "",
      editedIndex: -1,
      loadingPrecios: false,

      idproducto: "",
      idcambio_precio: "",
      cambio: "",
      moneda: "",
      fecha: "",

      monedas: [
        { text: "Soles", value: "S" },
        { text: "Dolares", value: "D" },
      ],

      igv: 0,
      tc: 0,

      desabilitar: false,
      isValid: true,

      adModal: 0,
      adId: "",
      adFecha: "",

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFechaBuscar: false,
    };
  },

  computed: {
    ...mapState(["usuario", "datos"]),

    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Cambio de Precios"
        : "Ver Cambio de Precios";
    },

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
    this.igv = parseFloat(this.datos.igv);
    this.tc = parseFloat(this.datos.tc);
  },

  methods: {
    ...mapActions(["snackBar"]),

    listar() {
      let me = this;
      me.loading = true;

      axios.get("api/Cambio_Precios/Listar", {
        params: {
          fechaInicio: me.dates[0],
          fechaFin:
            me.dates[1] == undefined
              ? me.dates[0] + " 23:59:59"
              : me.dates[1] + " 23:59:59",
        },
      })

        .then(function (response) {
          me.detCambios = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    guardar() {
      let me = this;

      // Validamos si el detalle esta vacio
      if (me.detPreciosCarga.length <= 0) {
        return me.snackBar({ cl: "warning", msg: "No hay datos disponibles en el detalle ." });
      }

      me.desabilitar = true;
      me.detPreciosDecimal = [];
      me.convertirDetPreciosDecimal();

      if (me.editedIndex > -1) {
        // Editar
        axios.put("api/Cambio_Precios/Actualizar", {
          idusuario: parseInt(me.usuario.idusuario),
          moneda: me.moneda,
          cambio: me.tc,
          detalle_cambio_precios: me.detPreciosDecimal,
        })

          .then(function (response) {
            me.desabilitar = false;
            me.snackBar({ cl: "success", msg: response.data });
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
          });
      } else {
        // Guardar
        axios.post("api/Cambio_Precios/Crear", {
          idusuario: parseInt(me.usuario.idusuario),
          moneda: me.moneda,
          cambio: me.tc,
          detalle_cambio_precios: me.detPreciosDecimal,
        })
          .then(function (response) {
            me.desabilitar = false;
            me.snackBar({ cl: "success", msg: response.data });
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
          });
      }
    },

    convertirDetPreciosDecimal() {
      let me = this;
      for (var i = 0; i < me.detPreciosCarga.length; i++) {
        me.detPreciosDecimal.push({
          idproducto: me.detPreciosCarga[i].idproducto,
          // Precio compra anterior
          precio_unit_comprad_ant: me.common.valDec(
            me.detPreciosCarga[i].precio_unit_comprad_ant
          ),
          precio_unit_compras_ant: me.common.valDec(
            me.detPreciosCarga[i].precio_unit_compras_ant
          ),
          // Precio compra nuevo
          precio_unit_comprad: me.common.valDec(
            me.detPreciosCarga[i].precio_unit_comprad
          ),
          precio_unit_compras: me.common.valDec(
            me.detPreciosCarga[i].precio_unit_compras
          ),

          //otros anterior
          otrosd_ant: me.detPreciosCarga[i].otrosd_ant,
          otross_ant: me.detPreciosCarga[i].otross_ant,
          // otros nuevo
          otrosd: me.common.valDec(me.detPreciosCarga[i].otrosd),
          otross: me.common.valDec(me.detPreciosCarga[i].otross),

          // Utilidad de producto anterior
          utilidad_unit_venta_ant:
            me.detPreciosCarga[i].utilidad_unit_venta_ant,
          utilidad_unit_cliente_ant:
            me.detPreciosCarga[i].utilidad_unit_cliente_ant,
          utilidad_unit_distribuidor_ant:
            me.detPreciosCarga[i].utilidad_unit_distribuidor_ant,
          // Utilidad de producto nuevo Ganancia%=(pv-pc/pc)*100
          utilidad_unit_venta: me.common.roundOut(
            ((me.common.valDec(me.detPreciosCarga[i].precio_unit_venta) -
              (me.common.valDec(me.detPreciosCarga[i].precio_unit_compras) +
                me.common.valDec(me.detPreciosCarga[i].otross))) /
              (me.common.valDec(me.detPreciosCarga[i].precio_unit_compras) +
                me.common.valDec(me.detPreciosCarga[i].otross))) *
            100,
            4
          ),
          utilidad_unit_cliente: me.common.roundOut(
            ((me.common.valDec(me.detPreciosCarga[i].precio_unit_cliente) -
              (me.common.valDec(me.detPreciosCarga[i].precio_unit_compras) +
                me.common.valDec(me.detPreciosCarga[i].otross))) /
              (me.common.valDec(me.detPreciosCarga[i].precio_unit_compras) +
                me.common.valDec(me.detPreciosCarga[i].otross))) *
            100,
            4
          ),
          utilidad_unit_distribuidor: me.common.roundOut(
            ((me.common.valDec(me.detPreciosCarga[i].precio_unit_distribuidor) -
              (me.common.valDec(me.detPreciosCarga[i].precio_unit_compras) +
                me.common.valDec(me.detPreciosCarga[i].otross))) /
              (me.common.valDec(me.detPreciosCarga[i].precio_unit_compras) +
                me.common.valDec(me.detPreciosCarga[i].otross))) *
            100,
            4
          ),
          // Precio unitario anterior
          precio_unit_venta_ant: me.common.valDec(
            me.detPreciosCarga[i].precio_unit_venta_ant
          ),
          precio_unit_cliente_ant: me.common.valDec(
            me.detPreciosCarga[i].precio_unit_cliente_ant
          ),
          precio_unit_distribuidor_ant: me.common.valDec(
            me.detPreciosCarga[i].precio_unit_distribuidor_ant
          ),
          // Precio unitario nuevo
          precio_unit_venta: me.common.valDec(
            me.detPreciosCarga[i].precio_unit_venta
          ),
          precio_unit_cliente: me.common.valDec(
            me.detPreciosCarga[i].precio_unit_cliente
          ),
          precio_unit_distribuidor: me.common.valDec(
            me.detPreciosCarga[i].precio_unit_distribuidor
          ),
        });
      }
    },

    /* Modal Anular */
    anular() {
      let me = this;

      axios.put("api/Cambio_Precios/AnularCambioPrecio/" + me.adId)
        .then(function (response) {
          me.snackBar({ cl: "success", msg: response.data });
          me.adModal = 0;
          me.adId = "";
          me.adFecha = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
          me.snackBar({ cl: "error", msg: error.response.data });
          me.adModal = 0;
          me.adId = "";
          me.adFecha = "";
        });
    },

    anularMostrar(item) {
      this.adModal = 1;
      this.adId = item.idcambio_precio;
      this.adFecha = item.fecha;
    },

    anularCerrar() {
      this.adModal = 0;
    },
    /* Fin Modal Anular */

    editItem(item) {
      this.idproducto = item.idproducto;
      this.moneda = item.moneda;
      this.fecha = item.fecha; // Solo para visualizar en el detalle
      this.idcambio_precio = item.idcambio_precio;
      this.listarDetalleCambioPrecio(this.idcambio_precio);
      this.editedIndex = 1;
      this.dialog = true;
    },

    listarDetalleCambioPrecio(id) {
      let me = this;
      var detallesArray = [];

      axios.get("api/Cambio_Precios/ListarDetallesCambioPrecios/" + id)
        .then(function (response) {
          detallesArray = response.data; // recorremos el array de detallesArray de ventas para formatear el precio_unit_venta
          detallesArray.map(function (x) {
            me.detPreciosCarga.push({
              idproducto: x.idproducto,
              fecha_modificacion: me.fecha, // Jalamos la fecha de tabla cambio_precio
              descripcion: x.descripcion,
              // Precio compra anterior
              precio_unit_comprad_ant: x.precio_unit_comprad_ant,
              precio_unit_compras_ant: x.precio_unit_compras_ant,
              // Precio compra nuevo
              precio_unit_comprad: x.precio_unit_comprad,
              precio_unit_compras: x.precio_unit_compras,

              //otros anterior
              otrosd_ant: x.otrosd_ant,
              otross_ant: x.otross_ant,
              // otros nuevo
              otrosd: x.otrosd,
              otross: x.otross,

              // Utilidad de producto anterior
              utilidad_unit_venta_ant: x.utilidad_unit_venta_ant,
              utilidad_unit_cliente_ant: x.utilidad_unit_cliente_ant,
              utilidad_unit_distribuidor_ant: x.utilidad_unit_distribuidor_ant,
              // Utilidad de producto
              utilidad_unit_venta: x.utilidad_unit_venta,
              utilidad_unit_cliente: x.utilidad_unit_cliente,
              utilidad_unit_distribuidor:
                x.utilidad_unit_distribuidor,
              // Precio unitario anterior
              precio_unit_venta_ant: x.precio_unit_venta_ant,
              precio_unit_cliente_ant: x.precio_unit_cliente_ant,
              precio_unit_distribuidor_ant:
                x.precio_unit_distribuidor_ant,
              // Precio unitario nuevo
              precio_unit_venta: x.precio_unit_venta,
              precio_unit_cliente: x.precio_unit_cliente,
              precio_unit_distribuidor: x.precio_unit_distribuidor,
            });
          });
          // console.log(me.detalles);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    close() {
      this.dialog = false;
      this.loadingPrecios = false;
      this.limpiar();
    },

    limpiar() {
      this.idproducto = "";
      this.moneda = "";
      this.fecha = "";
      this.buscarProducto = "";

      this.detPreciosCarga = [];
      this.detPreciosDecimal = [];

      this.editedIndex = -1;
    },

    listarPrecios() {
      let me = this;
      me.loadingPrecios = true;
      me.detPreciosCarga = [];
      var detallesArray = [];

      axios.get("api/Cambio_Precios/ListarPreciosProducto", {
        params: { moneda: me.moneda, cambio: me.tc },
      })
        .then(function (response) {
          detallesArray = response.data;
          detallesArray.map(function (x) {
            me.detPreciosCarga.push({
              idproducto: x.idproducto,
              fecha_modificacion: x.fecha_modificacion,
              descripcion: x.descripcion,
              // Precio compra anterior
              precio_unit_comprad_ant: x.precio_unit_comprad_ant,
              precio_unit_compras_ant: x.precio_unit_compras_ant,
              // Precio compra nuevo
              precio_unit_comprad: x.precio_unit_comprad,
              precio_unit_compras: x.precio_unit_compras,
              //otros anterior
              otrosd_ant: x.otrosd_ant,
              otross_ant: x.otross_ant,
              // otros nuevo
              otrosd: x.otrosd,
              otross: x.otross,
              // Utilidad de producto anterior
              utilidad_unit_venta_ant: x.utilidad_unit_venta_ant,
              utilidad_unit_cliente_ant: x.utilidad_unit_cliente_ant,
              utilidad_unit_distribuidor_ant: x.utilidad_unit_distribuidor_ant,
              // Utilidad de producto
              utilidad_unit_venta: x.utilidad_unit_venta,
              utilidad_unit_cliente: x.utilidad_unit_cliente,
              utilidad_unit_distribuidor:
                x.utilidad_unit_distribuidor,
              // Precio unitario anterior
              precio_unit_venta_ant: x.precio_unit_venta_ant,
              precio_unit_cliente_ant: x.precio_unit_cliente_ant,
              precio_unit_distribuidor_ant:
                x.precio_unit_distribuidor_ant,
              // Precio unitario nuevo
              precio_unit_venta: x.precio_unit_venta,
              precio_unit_cliente: x.precio_unit_cliente,
              precio_unit_distribuidor: x.precio_unit_distribuidor,
            });
          });
          me.loadingPrecios = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    eliminarItemDetallePrecio(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },
  },
};
</script>