<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout alig-start>
      <v-flex>
        <v-data-table :loading="loading" :headers="cabeceraCompraDetallada" :items="comprasDetalladas"
          :items-per-page="15" :search="buscar" dense class="elevation-3">
          <template v-slot:top>
            <v-form v-model="isValid">
              <v-toolbar flat>
                <v-toolbar-title class="hidden-sm-and-down">COMPRAS DETALLADAS</v-toolbar-title>
                <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
                <!-- Formulario busqueda -->
                <v-btn icon small color="success" @click="crearXLSX()" :disabled="desabilitar || !isValid" class="mr-1">
                  <v-icon>description</v-icon>
                </v-btn>
                <v-spacer />
                <v-autocomplete dense v-model="idsucursal" :items="selectSucursales" :search-input.sync="buscar_sucursal"
                  label="Establecimiento" :rules="[v => !!v || 'Requerido']" class="mr-1" @change="comprasDetalladas = []"
                  hide-details></v-autocomplete>
                <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                  min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                  <template v-slot:activator="{ on }">
                    <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details class="mr-1"
                      style="max-width: 190px;">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true" range>
                  </v-date-picker>
                </v-menu>
                <v-text-field v-model.trim="buscar" dense label="Búsqueda" single-line hide-details class="mr-1"
                  :disabled="desabilitar || !isValid"></v-text-field>
                <v-btn icon small color="primary" @click="listar" :loading="desabilitar"
                  :disabled="desabilitar || !isValid">
                  <v-icon>search</v-icon>
                </v-btn>
                <v-spacer />
                <v-switch v-model="todoCompra" hide-details label="Todo" class="hidden-sm-and-down">
                </v-switch>
                <!-- Fin Formulario Busqueda -->
              </v-toolbar>
            </v-form>
          </template>
          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>

          <template v-slot:item.fecha_comprobante="{ item }">
            <span>{{ item.fecha_comprobante | formatearFecha }}</span>
          </template>
          <template v-slot:item.descripcion="{ item }">
            <span>{{ item.descripcion }}</span>
          </template>
          <template v-slot:item.valor_unit_compra="{ item }">
            <div class="text-right">
              <span :class="item.moneda == 'S' ? 'primary--text' : 'success--text'">{{ item.valor_unit_compra |
                formatearNumero
              }}</span>
            </div>
          </template>
          <template v-slot:item.descto_item="{ item }">
            <div class="text-right">
              <span :class="item.moneda == 'S' ? 'primary--text' : 'success--text'">{{ item.descto_item |
                formatearNumero
              }}</span>
            </div>
          </template>
          <template v-slot:item.valor_compra_total="{ item }">
            <div class="text-right">
              <strong :class="item.moneda == 'S' ? 'primary--text' : 'success--text'">{{
                common.roundOut(item.stock_ingreso * item.valor_unit_compra - item.descto_item, 2) | formatearNumero
              }}</strong>
            </div>
          </template>
          <template v-slot:item.igv="{ item }">
            <div class="text-right">
              <strong :class="item.moneda == 'S' ? 'primary--text' : 'success--text'">{{
                common.roundOut((item.stock_ingreso * item.valor_unit_compra - item.descto_item) * item.igv, 2) |
                formatearNumero
              }}</strong>
            </div>
          </template>
          <template v-slot:item.precio_compra_total="{ item }">
            <div class="text-right">
              <strong :class="item.moneda == 'S' ? 'primary--text' : 'success--text'">{{
                common.roundOut((item.stock_ingreso * item.valor_unit_compra - item.descto_item) + ((item.stock_ingreso
                  * item.valor_unit_compra - item.descto_item) * item.igv), 2)
                | formatearNumero
              }}</strong>
            </div>
          </template>
          <template v-slot:item.otros_item="{ item }">
            <div class="text-right">
              <span :class="item.moneda == 'S' ? 'primary--text' : 'success--text'">{{
                common.roundOut(item.stock_ingreso * item.otros_item, 2) | formatearNumero
              }}</span>
            </div>
          </template>
          <!-- <template v-slot:item.precio_publico="{ item }">
            <div class="text-right">
              <span class="primary--text">{{ item.precio_publico | formatearNumero }}</span>
            </div>
          </template>-->
          <template v-slot:item.stock_actual="{ item }">
            <div class="text-center">
              <strong>{{ item.stock_actual }}</strong>
            </div>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import XLSX from "xlsx";

export default {
  data() {
    return {
      comprasDetalladas: [],
      dialog: false,
      cabeceraCompraDetallada: [

        { text: "Fecha", value: "fecha", sortable: false },
        //{ text: "CdSc", value: "idsucursal" },
        //{ text: "CdUs", value: "idusuario" },
        // text: "CdMv", value: "idmovimiento" },
        { text: "Proveedor", value: "proveedor", sortable: false },
        { text: "Fecha Comprobante", value: "fecha_comprobante", sortable: false, },
        { text: "Comprobante", value: "comprobante", sortable: false },
        { text: "Descripción", value: "descripcion", sortable: false },
        { text: "Serie", value: "serie", sortable: false, divider: true },
        { text: "Stock Ingresado", value: "stock_ingreso", align: "center", sortable: false, divider: true },
        { text: "Valor Unitario", value: "valor_unit_compra", sortable: false },
        { text: "Dscto", value: "descto_item", sortable: false },
        { text: "Valor Compra", value: "valor_compra_total", sortable: false },
        { text: "IGV", value: "igv", sortable: false },
        { text: "Precio Compra", value: "precio_compra_total", sortable: false, },
        { text: "Otros", value: "otros_item", sortable: false, divider: true },
        // { text: "P/U/Vta Publico", value: "precio_publico", align: "center", sortable: false,},
        { text: "Stock Actual", value: "stock_actual", align: "center", sortable: false, },
      ],
      idsucursal: "",
      selectSucursales: [],
      buscar_sucursal: "",
      buscar: "",

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      todoCompra: false,

      desabilitar: false,
      isValid: true,
      loading: false,
      desabilitarbuscar: true,
    };
  },

  computed: {
    ...mapState(["usuario"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  created() {
    this.select();
    this.idsucursal = parseInt(this.usuario.idsucursal);
  },

  methods: {
    ...mapActions(["snackBar"]),

    listar() {
      let me = this;

      me.desabilitar = true;
      me.loading = true;

      axios.get("api/Movimientos/ReporteCompraDetallada", {
        params: {
          idsucursal: parseInt(me.idsucursal),
          fechaInicio: me.dates[0],
          fechaFin:
            me.dates[1] == undefined
              ? me.dates[0] + " 23:59:59"
              : me.dates[1] + " 23:59:59",
          todo: me.todoCompra, // No incluimos si stock actual es menor a cero
        },
      })
        .then(function (response) {
          me.comprasDetalladas = response.data;
          me.desabilitar = false;
          me.loading = false;
          me.snackBar({ cl: "success", msg: "Procesado correctamente." });
          //console.log(me.comprasDetalladas);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.snackBar({ cl: "error", msg: "Ocurrio un error" });
          //console.log(err.response);
        });
    },

    select() {
      let su = this;
      var sucursalesArray = [];
      axios.get("api/Sucursales/Select")
        .then(function (response) {
          sucursalesArray = response.data;
          sucursalesArray.map(function (x) {
            su.selectSucursales.push({ text: x.nombre, value: x.idsucursal });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    crearXLSX() {
      var rows = [];

      for (var i = 0; i < this.comprasDetalladas.length; i++) {
        rows.push({
          CdSc: this.comprasDetalladas[i].idsucursal,
          CdUs: this.comprasDetalladas[i].idusuario,
          CdMv: this.comprasDetalladas[i].idmovimiento,
          Fecha: moment(this.comprasDetalladas[i].fecha).format("DD/MM/YYYY"),
          Proveedor: this.comprasDetalladas[i].proveedor,
          "Fecha Comprobante": moment(
            this.comprasDetalladas[i].fecha_comprobante
          ).format("DD/MM/YYYY"),
          Comprobante: this.comprasDetalladas[i].comprobante,
          Descripción: this.comprasDetalladas[i].descripcion,
          Serie: this.comprasDetalladas[i].serie,
          Código: this.comprasDetalladas[i].codigo_producto,
          Categoría: this.comprasDetalladas[i].categoria,
          "Stock Ingresado": this.comprasDetalladas[i].stock_ingreso,
          Moneda: this.comprasDetalladas[i].moneda == "S" ? "Soles" : "Dolares",
          "Valor Unitario": this.comprasDetalladas[i].valor_unit_compra,
          Descuento: this.comprasDetalladas[i].descto_item,
          "Valor Compra": this.common.roundOut(this.comprasDetalladas[i].stock_ingreso * this.comprasDetalladas[i].valor_unit_compra - this.comprasDetalladas[i].descto_item, 2),
          IGV: this.common.roundOut((this.comprasDetalladas[i].stock_ingreso * this.comprasDetalladas[i].valor_unit_compra - this.comprasDetalladas[i].descto_item) * this.comprasDetalladas[i].igv, 2),
          "Precio Compra": this.common.roundOut((this.comprasDetalladas[i].stock_ingreso * this.comprasDetalladas[i].valor_unit_compra - this.comprasDetalladas[i].descto_item) + (this.comprasDetalladas[i].stock_ingreso * this.comprasDetalladas[i].valor_unit_compra - this.comprasDetalladas[i].descto_item) * this.comprasDetalladas[i].igv, 2),
          Otros: this.common.roundOut(this.comprasDetalladas[i].stock_ingreso * this.comprasDetalladas[i].otros_item, 2),
          //"P/U Vta Publico S/": this.comprasDetalladas[i].precio_publico,
          "Stock Actual": this.comprasDetalladas[i].stock_actual,
        });
      }
      var Detalle = XLSX.utils.json_to_sheet(rows);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, Detalle, "Compras Detalladas");
      XLSX.writeFile(
        wb,
        "ComprasDetalladas " +
        moment(this.dates[0]).format("DD-MM-YYYY") +
        " al " +
        moment(this.dates[1]).format("DD-MM-YYYY") +
        ".xlsx"
      );
    },
  },
};
</script>

