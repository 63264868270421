<template>
    <v-container fluid style="max-width: 1280px;">
        <v-layout alig-start>
            <v-flex>
                <!-- Listado conductor-->
                <v-data-table :loading="loading" :headers="headers" :items="conductores" :items-per-page="15" dense
                    class="elevation-3">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title class="hidden-sm-and-down">CONDUCTORES</v-toolbar-title>
                            <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
                            <!-- Impresion conductor -->
                            <v-btn icon small color="print" @click="crearPDF()" class="mr-1">
                                <v-icon>print</v-icon>
                            </v-btn>
                            <!-- Fin Impresion conductor -->
                            <v-spacer />
                            <!-- Formulario busqueda-->
                            <v-text-field class="mr-1 text-xs-center" v-model.trim="buscar" append-icon="search" dense
                                label="Búsqueda" @keyup="listar()" single-line hide-details></v-text-field>
                            <!-- Fin Formulario Busqueda-->
                            <v-spacer />
                            <!-- Modal crear y modificar conductor -->
                            <v-dialog v-model="dialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn color="primary" class="hidden-sm-and-down" v-on="on" small outlined>Nuevo</v-btn>
                                    <v-btn color="primary" class="hidden-md-and-up" v-on="on" fab x-small outlined>
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-toolbar dark dense class="primary" flat>
                                        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-form v-model="isValid">
                                            <v-row class="mt-2">
                                                <v-col cols="6" sm="4">
                                                    <v-select dense v-model="idtipo_documento" :items="tipo_documentos"
                                                        label="Tipo Documento"
                                                        :rules="[v => !!v || 'Tipo documento requerido']"
                                                        @change="num_documento = ''" :disabled="true" hide-details>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="10" sm="6">
                                                    <v-text-field dense v-model.trim="num_documento"
                                                        label="Numero Documento"
                                                        :rules="[v => (!!v && v > 0) || 'Numero documento requerido', v => (idtipo_documento == 1 ? v.length == 8 || 'Numero DNI' : v.length == 11 || 'Numero RUC')]"
                                                        :maxlength="idtipo_documento == 1 ? 8 : 11"
                                                        @keypress="common.isNum($event)" hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="2" sm="1">
                                                    <div class="text-center">
                                                        <v-btn x-small fab color="primary" class="white--text"
                                                            :loading="desabilitarBuscar"
                                                            :disabled="(idtipo_documento == 1 && num_documento.length == 8 ? false : idtipo_documento == 6 && num_documento.length == 11 ? false : true) || desabilitarBuscar"
                                                            @click="extraerDocumento(num_documento)" outlined>
                                                            <v-icon>search</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" sm="5">
                                                    <v-text-field dense v-model.trim="nombres" label="Nombres"
                                                        :rules="[v => !!v || 'Nombres requerido', v => (!!v && v.length >= 3 && v.length <= 100) || 'Nombre debe tener entre 3 a 100 caracteres', Rules.unespacio]"
                                                        maxlength="100" prepend-inner-icon="person" v-uppercase
                                                        hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="7">
                                                    <v-text-field dense v-model.trim="apellidos" label="Apellidos"
                                                        :rules="[v => !!v || 'Apellidos requerido', v => (!!v && v.length >= 1 && v.length <= 100) || 'Apellidos debe tener entre 1 a 100 caracteres', Rules.unespacio]"
                                                        maxlength="100" v-uppercase hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field dense v-model.trim="lic_conducir" label="Licencia"
                                                        :rules="[v => !!v || 'Licencia requerido', v => (!!v && v.length >= 9 && v.length <= 10) || 'Licencia debe tener entre 9 a 10 caracteres', Rules.sinespacio]"
                                                        maxlength="10" prepend-inner-icon="badge" v-uppercase
                                                        hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field dense v-model.trim="email" label="Email"
                                                        :rules="[v => v == '' ? !v : /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                                                        maxlength="60" prepend-inner-icon="mail" v-lowercase
                                                        hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="5">
                                                    <v-text-field dense v-model.trim="telefono" label="Teléfono"
                                                        :rules="[v => v.length <= 30 || 'El Teléfono no debe tener mas de 30 caracteres', Rules.unespacio]"
                                                        maxlength="30" prepend-inner-icon="phone" v-uppercase
                                                        hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="7">
                                                    <v-text-field dense v-model.trim="direccion" label="Dirección"
                                                        :rules="[v => v.length <= 200 || 'La dirección no debe tener mas de 200 caracteres', Rules.unespacio]"
                                                        maxlength="200" prepend-inner-icon="place" v-uppercase
                                                        hide-details></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions class="py-0 pb-4 px-4 justify-center">
                                        <v-btn color="error" @click="close" small outlined>
                                            <v-icon left>close</v-icon>Cancelar
                                        </v-btn>
                                        <v-btn color="primary" @click="guardar" :loading="desabilitar"
                                            :disabled="desabilitar || !isValid" small outlined>
                                            <v-icon left>check</v-icon>Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <!-- Fin Modal crear y modificar conductor -->

                            <!-- Modal activar, desactivar -->
                            <v-dialog v-model="adModal" max-width="290">
                                <v-card>
                                    <v-card-title class="justify-center" v-if="adAccion == 1">¿Activar
                                        Conductor?</v-card-title>
                                    <v-card-title class="justify-center" v-if="adAccion == 2">¿Desactivar
                                        Conductor?</v-card-title>
                                    <v-card-text>
                                        Estás a punto de
                                        <span v-if="adAccion == 1">Activar</span>
                                        <span v-if="adAccion == 2">Desactivar</span>
                                        el Conductor {{ adNombre }}
                                    </v-card-text>
                                    <v-card-actions class="py-0 pb-4 justify-center">
                                        <v-btn color="success" @click="activarDesactivarCerrar" small
                                            outlined>Cancelar</v-btn>
                                        <v-btn v-if="adAccion == 1" color="info" @click="activar" small
                                            outlined>Activar</v-btn>
                                        <v-btn v-if="adAccion == 2" color="info" @click="desactivar" small
                                            outlined>Desactivar</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <!-- Fin Modal activar, desactivar -->
                        </v-toolbar>
                    </template>
                    <!-- Opciones -->
                    <template v-slot:item.nombres="{ item }">
                        <span>{{ item.nombres + " " + item.apellidos }}</span>
                    </template>
                    <template v-slot:item.condicion="{ item }">
                        <template v-if="item.condicion">
                            <v-btn text small color="success" @click="activarDesactivarMostrar(2, item)">Activo</v-btn>
                        </template>
                        <template v-else>
                            <v-btn text small color="info" @click="activarDesactivarMostrar(1, item)">Inactivo</v-btn>
                        </template>
                    </template>
                    <template v-slot:item.opciones="{ item }">
                        <v-btn small icon>
                            <v-icon color="primary" @click="editItem(item)">edit</v-icon>
                        </v-btn>
                        <v-btn small icon>
                            <v-icon color="error" @click="deleteItem(item)">delete_forever</v-icon>
                        </v-btn>
                    </template>
                    <!-- Fin Opciones -->
                </v-data-table>
                <!-- Fin Listado conductor-->
            </v-flex>
        </v-layout>
    </v-container>
</template>
  
<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState, mapActions } from "vuex";

export default {
    data() {
        return {
            conductores: [],
            dialog: false,
            headers: [
                { text: "NOMBRE", value: "nombres", sortable: false },
                { text: "DNI", value: "num_documento", sortable: false },
                { text: "LICENCIA", value: "lic_conducir", sortable: false },
                { text: "TELÉFONO", value: "telefono", sortable: false },
                { text: "CONDICIÓN", value: "condicion", sortable: false },
                { text: "OPCIÓN", value: "opciones", sortable: false, width: 100 }
            ],
            buscar: "",
            desabilitarBuscar: false,

            editedIndex: -1,

            idconductor: "",
            nombres: "",
            apellidos: "",
            idtipo_documento: 1,
            num_documento: "",
            lic_conducir: "",
            direccion: "",
            telefono: "",
            email: "",

            tipo_documentos: [
                { text: "DNI", value: 1 },
                { text: "RUC", value: 6 }
            ],

            adModal: 0,
            adAccion: 0,
            adNombre: "",
            adId: "",

            desabilitar: false,
            isValid: true,

            /* Validaciones */
            Rules: {
                sinespacio: (v) =>
                    (v || "").indexOf(" ") < 0 || "No se permite espacios",
                unespacio: (v) =>
                    (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
            },
        };
    },

    computed: {
        ...mapState(["logo"]),

        formTitle() {
            return this.editedIndex === -1
                ? "Nuevo Conductor"
                : "Actualizar Conductor";
        },
    },

    watch: {
        dialog(val) {
            val || this.close();
        },
    },

    created() {
        this.listar();
    },
    methods: {
        ...mapActions(["snackBar"]),

        /* Listar conductor */
        listar() {
            let me = this;

            me.loading = true;
            axios.get("api/Conductores/ListarConductores", {
                params: {
                    buscar: me.buscar == "" ? "-" : me.buscar,
                },
            })
                .then(function (response) {
                    me.conductores = response.data;
                    me.loading = false;
                })
                .catch(function (error) {
                    //console.log(error);
                });
        },

        /* Editar y guardar conductor */
        guardar() {
            let me = this;
            me.desabilitar = true;

            if (this.editedIndex > -1) {
                //Código para editar
                axios.put("api/Conductores/Actualizar", {
                    idconductor: me.idconductor,
                    nombres: me.nombres,
                    apellidos: me.apellidos,
                    idtipo_documento: me.idtipo_documento,
                    num_documento: me.num_documento,
                    lic_conducir: me.lic_conducir,
                    direccion: me.direccion,
                    telefono: me.telefono,
                    email: me.email
                })
                    .then(function (response) {
                        me.desabilitar = false;
                        me.snackBar({ cl: "success", msg: response.data });
                        me.close();
                        me.listar();
                        me.limpiar();
                    })
                    .catch(function (error) {
                        me.desabilitar = false;
                        me.snackBar({ cl: "error", msg: error.response.data });
                        me.listar();
                    });
            } else {
                //Código para guardar
                axios.post("api/Conductores/Crear", {
                    nombres: me.nombres,
                    apellidos: me.apellidos,
                    idtipo_documento: me.idtipo_documento,
                    num_documento: me.num_documento,
                    lic_conducir: me.lic_conducir,
                    direccion: me.direccion,
                    telefono: me.telefono,
                    email: me.email
                })
                    .then(function (response) {
                        me.desabilitar = false;
                        me.snackBar({ cl: "success", msg: response.data });
                        me.close();
                        me.listar();
                        me.limpiar();
                    })
                    .catch(function (error) {
                        me.desabilitar = false;
                        me.snackBar({ cl: "error", msg: error.response.data });
                        me.listar();
                    });
            }
        },

        /* Eliminar conductor */
        deleteItem(item) {
            const respta = confirm(
                "¿Estás seguro de que deseas eliminar el conductor " +
                item.nombres +
                "?"
            );
            if (respta) {
                let me = this;
                axios.delete("api/Conductores/Eliminar/" + item.idconductor)
                    .then((response) => {
                        me.snackBar({ cl: "success", msg: response.data });
                        this.listar();
                    })
                    .catch(function (error) {
                        me.snackBar({ cl: "error", msg: error.response.data });
                    });
            }
        },

        /* Editar conductor item */
        editItem(item) {
            this.idconductor = item.idconductor;
            this.nombres = item.nombres;
            this.apellidos = item.apellidos;
            this.idtipo_documento = item.idtipo_documento;
            this.num_documento = item.num_documento;
            this.lic_conducir = item.lic_conducir;
            this.direccion = item.direccion;
            this.telefono = item.telefono;
            this.email = item.email;

            this.editedIndex = 1;
            this.dialog = true;
        },

        extraerDocumento(numero) {
            let me = this;
            me.desabilitarBuscar = true;

            var token =
                "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpmcmFpbkBob3RtYWlsLmVzIn0.tT4tLoVKqPHNbwZsFbim_mUkJkz5zZtseefC0s7RBFs";
            if (me.idtipo_documento == 1) {
                fetch(
                    "https://dniruc.apisperu.com/api/v1/dni/" +
                    numero +
                    "?token=" +
                    token
                )
                    .then((response) => response.json())
                    .then(function (data) {
                        me.desabilitarBuscar = false;
                        if (data.message == undefined) {
                            me.nombres = data.nombres;
                            me.apellidos = data.apellidoPaterno + " " + data.apellidoMaterno;
                        } else {
                            me.nombres = "";
                            me.apellidos = "";
                            me.snackBar({ cl: "info", msg: data.message }); // Sin resultados
                        }
                    })
                    .catch(function () {
                        me.desabilitarBuscar = false;
                        me.snackBar({ cl: "error", msg: "Error en el Nº DNI." });
                    });
            } else if (me.idtipo_documento == 6) {
                axios.post("api/Personas/Padron", {
                    ruc: me.numero,
                })
                    .then(function (response) {
                        me.desabilitarBuscar = false;
                        me.nombre = response.data.razon;
                        me.telefono =
                            response.data.telefono == null
                                ? ""
                                : response.data.telefono.trim();
                        me.snackBar({ cl: "info", msg: "Contribuyente con estado " + response.data.estado + " y condición " + response.data.condicion });
                    })
                    .catch(function (error) {
                        me.desabilitarBuscar = false;
                        me.nombre = "";
                        me.telefono = "";
                        me.snackBar({ cl: "error", msg: error.response.data });
                    });
            }
        },

        /* Cerrar ventana conductor */
        close() {
            this.dialog = false;
            this.limpiar();
        },

        /* Limpiar conductor item */
        limpiar() {
            this.idconductor = "";
            this.nombres = "";
            this.apellidos = "";
            //this.idtipo_documento = "";
            this.num_documento = "";
            this.lic_conducir = "";
            this.direccion = "";
            this.telefono = "";
            this.email = "";
            this.editedIndex = -1;
        },

        /* Desactivar, activar conductor */
        desactivar() {
            let me = this;

            axios.put("api/Conductores/Desactivar/" + this.adId, {})
                .then(function (response) {
                    me.adModal = 0;
                    me.adAccion = 0;
                    me.adNombre = "";
                    me.adId = "";
                    me.listar();
                })
                .catch(function (error) {
                    //console.log(error);
                });
        },

        activarDesactivarMostrar(accion, item) {
            this.adModal = 1;
            this.adNombre = item.nombres;
            this.adId = item.idconductor;
            if (accion == 1) {
                this.adAccion = 1;
            } else if (accion == 2) {
                this.adAccion = 2;
            } else {
                this.adModal = 0;
            }
        },

        activarDesactivarCerrar() {
            this.adModal = 0;
        },

        activar() {
            let me = this;
            axios.put("api/Conductores/Activar/" + this.adId, {})
                .then(function (response) {
                    me.adModal = 0;
                    me.adAccion = 0;
                    me.adNombre = "";
                    me.adId = "";
                    me.listar();
                })
                .catch(function (error) {
                    //console.log(error);
                });
        },

        crearPDF() {
            var columns = [
                { header: "DNI", dataKey: "num_documento" },
                { header: "NOMBRE", dataKey: "nombres" },
                { header: "LICENCIA", dataKey: "lic_conducir" },
                { header: "DIRECCIÓN", dataKey: "direccion" },
                { header: "TELÉFONO", dataKey: "telefono" },
                { header: "EMAIL", dataKey: "email" },
                { header: "CONDICION", dataKey: "condicion" },
            ];
            var body = [];

            this.conductores.map(function (x) {
                body.push({
                    num_documento: x.num_documento,
                    nombres: x.nombres + " " + x.apellidos,
                    lic_conducir: x.lic_conducir,
                    direccion: x.direccion,
                    telefono: x.telefono,
                    email: x.email,
                    condicion: x.condicion == 1 ? "Activo" : "Inactivo",
                });
            });

            //console.log(body);

            // Titulo y logo
            const addHeaders = (doc) => {
                const pageCount = doc.internal.getNumberOfPages();

                doc.setFont("helvetica", "bold");
                doc.setFontSize(10);
                for (var i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    doc.text(
                        "LISTADO DE CONDUCTORES",
                        doc.internal.pageSize.width / 2,
                        45,
                        {
                            align: "center",
                        }
                    );
                    doc.addImage(imgData, "JPEG", 40, 17, 70, 49.39);
                }
            };
            // Pie de pagina
            const addFooters = (doc) => {
                const pageCount = doc.internal.getNumberOfPages();

                doc.setFont("helvetica", "italic");
                doc.setFontSize(7);
                for (var i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    doc.text(
                        "Pagina " + String(i) + " de " + String(pageCount),
                        doc.internal.pageSize.width / 2,
                        820,
                        {
                            align: "center",
                        }
                    );
                }
            };

            var imgData = "data:image/png;base64," + this.logo;
            var doc = new jsPDF("l", "pt");

            doc.autoTable({
                columns,
                body,
                margin: { top: 70 },
                styles: { overflow: "ellipsize", cellWidth: "wrap" },
                headStyles: {
                    fillColor: [46, 78, 121],
                    fontSize: 7,
                    halign: "center",
                },
                bodyStyles: { textColor: 0, fontSize: 8 },
                columnStyles: {
                    num_documento: { cellWidth: "30" },
                    nombres: { cellWidth: "auto" },
                    lic_conducir: { cellWidth: "20" },
                    direccion: { cellWidth: "100" },
                    telefono: { cellWidth: "40" },
                    email: { cellWidth: "40" },
                    condicion: { cellWidth: "115", halign: "center" },
                },
            });

            addHeaders(doc);
            addFooters(doc);
            doc.save("Conductores.pdf");
        },
    },
};
</script>